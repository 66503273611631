import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button';
import { UpdateCustomAppLead } from '../../utils/urls';
import axios from '../../utils/axios'
import { Field } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { City, State } from 'country-state-city';
import './profile.css'
import { InputTextarea } from 'primereact/inputtextarea';


function ShoppingFor({ data, toast, formId, userId, setIsSubmit }) {

    const [isEditOpen, setIsEditOpen] = useState(false);

    const [formData, setFormData] = useState({
        home_type: data?.home_type || [],
        bedrooms: data?.bedrooms || '',
        bathrooms: data?.bathrooms || '',
        price_range: data?.unformatted_price_range || '',
        why_are_you_looking_to_buy_at_this_time: data?.why_are_you_looking_to_buy_at_this_time || '',
        notes_about_home_search: data?.notes_about_home_search || '',
        specific_area_interested_in: data?.specific_area_interested_in || [],
        // priority_state: data?.priority_state || '',
        // priority_city: data?.priority_city || ''
    });

    const [areaState, setAreaState] = useState("")
    const [areaCity, setAreaCity] = useState("")

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleEditClick = () => {
        setIsEditOpen(true);
    };

    const getStateIsoCode = (stateName) => {
        const states = State.getStatesOfCountry('US');
        const state = states.find((state) => state.name === stateName);
        return state ? state.isoCode : '';
    };

    const handleAddAreas = () => {
        // setFormData({
        //     ...formData,
        //     [e.target.id]: e.target.value,
        // });

        const newObject = {
            state: State.getStateByCodeAndCountry(areaState, 'US')?.name,
            city: areaCity,
            priority: formData.specific_area_interested_in.length === 0 ? true : false
        }
        setFormData((prevState) => ({
            ...prevState,
            specific_area_interested_in: [...prevState.specific_area_interested_in, newObject]
        }));
        setAreaState("")
        setAreaState("")
    }

    // console.log(formData)

    const handleEditSave = async () => {
        try {
            const updatedData = {
                ...formData,
                type: "Shopping for",
                userId: userId
            };
            console.log(updatedData);
            setIsEditOpen(false);
            console.log('Submit ')
            const url = `${UpdateCustomAppLead}${formId}`
            const response = await axios.post(url, updatedData, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Updated', life: 3000 });
                setIsSubmit(true)
                // onSubmitProps.resetForm();
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                setIsSubmit(true)
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            setIsSubmit(true)
        }
    };

    const handleEditCancel = () => {
        setIsEditOpen(false);
    };

    return (
        <FlexComponent flexDirection='column' style={{ borderRadius: '1rem', gap: '1.25rem', textAlign: 'center', padding: '2rem' }} className="shoppingForContainer">
            <div style={{ width: '100%', textAlign: 'start' }}>
                <FlexComponent style={{ textAlign: 'start', width: '100%' }}>
                    <p style={{ fontWeight: '600' }}>Shopping for</p>
                    <Button label="Edit" style={{ borderRadius: '25px', fontSize: '16px', padding: '5px 20px', border: '1px solid #E0E4EC', background: 'white', color: '#8C8C8C' }} onClick={handleEditClick} />
                </FlexComponent>
                <p style={{ marginTop: '1rem' }}>🏠 {data?.title || 'N/A'}</p>
                {/* <img width='100%' height={100} src="" alt="" /> */}
                <iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(data?.address)}&zoom=16&key=AIzaSyBAG_17fdNSB6fIuoy6KO5TX0mW5HqWwM8`}></iframe>
            </div>
            <FlexComponent >
                {/* <div style={{ textAlign: 'start', width: '60%' }}>
                    <p style={{ color: '#8C8C8C' }}>Specific Address of Interest:</p>
                    <p>Lorem Ipsum</p>
                </div> */}
                <div style={{ textAlign: 'start', width: '40%' }}>
                    <p style={{ color: '#8C8C8C' }}>Price Range</p>
                    <p>{data?.price_range}</p>
                </div>
            </FlexComponent>
            {/* <FlexComponent>
                <div style={{ textAlign: 'start', width: '60%' }}>
                    <p style={{ color: '#8C8C8C' }}>Other areas of interest:</p>
                    <p>Lorem Ipsum</p>
                </div>
                <div style={{ textAlign: 'start', width: '40%' }}>
                    <p style={{ color: '#8C8C8C' }}>Max desired payment</p>
                    <p>$</p>
                </div>
            </FlexComponent> */}
            <div style={{ textAlign: 'start', width: '100%' }}>
                <p style={{ color: '#8C8C8C' }}>Story</p>
                <p>{data?.story || 'N/A'}</p>
            </div>
            <FlexComponent>
                <div style={{ textAlign: 'start', width: '60%' }}>
                    <p style={{ color: '#8C8C8C' }}>Became a lead:</p>
                    <p>{data?.became_lead || 'N/A'}</p>
                </div>
                <div style={{ textAlign: 'start', width: '40%' }}>
                    <p style={{ color: '#8C8C8C' }}>Lead Source</p>
                    <p>{data?.lead_source || 'N/A'}</p>
                </div>
            </FlexComponent>
            <div style={{ textAlign: 'start', width: '100%' }}>
                <p style={{ color: '#8C8C8C' }}>Became an App</p>
                <p>{data?.became_app || 'N/A'}</p>
            </div>
            <Dialog className='profileEditForm' header="Edit Shopping for" visible={isEditOpen} onHide={() => handleEditCancel()}>
                <div >
                    <form onSubmit={handleEditSave} >
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="home_type">Home Type</label> <br />
                                {["1 Family", "2 Family", "3+ / Multi-Family", "Condo", "Co-op"].map((option) => (
                                    <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                        <Checkbox
                                            type="checkbox"
                                            id={option}
                                            name="home_type"
                                            value={option}
                                            checked={formData.home_type.includes(option)}
                                            onChange={(event) => {
                                                const updatedValues = [...formData.home_type];
                                                if (event.target.checked) {
                                                    updatedValues.push(option);
                                                } else {
                                                    const index = updatedValues.indexOf(option);
                                                    updatedValues.splice(index, 1);
                                                }
                                                // handleChange({ target: { name: 'home_type', value: updatedValues } });
                                                setFormData((prevState) => ({
                                                    ...prevState,
                                                    home_type: updatedValues
                                                }));
                                            }}
                                        />
                                        <label htmlFor={option} className="ml-2">{option}</label>
                                    </FlexComponent>
                                ))}
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="bedrooms">Bedrooms</label> <br />
                                <InputText style={{ width: '100%' }} id="bedrooms" defaultValue={data?.bedrooms} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="bathrooms">Bathrooms</label> <br />
                                <InputText type='number' style={{ width: '100%' }} id="bathrooms" defaultValue={data?.bathrooms} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="price_range">Price Range</label> <br />
                                <InputText type='number' style={{ width: '100%' }} id="price_range" defaultValue={data?.unformatted_price_range} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="why_are_you_looking_to_buy_at_this_time">Why are you looking to buy at this time?</label> <br />
                                <InputText style={{ width: '100%' }} id="why_are_you_looking_to_buy_at_this_time" defaultValue={data?.why_are_you_looking_to_buy_at_this_time} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="notes_about_home_search">Notes about home search</label> <br />
                                {/* <InputText style={{ width: '100%' }} id="notes_about_home_search" defaultValue={data?.notes_about_home_search} onChange={handleInputChange} /> */}
                                <InputTextarea rows={4} type="text" id="notes_about_home_search" name="notes_about_home_search" style={{ width: '100%' }}
                                value={formData.notes_about_home_search} onChange={handleInputChange}
                                    placeholder="" />
                            </div>
                        </FlexComponent>
                        <FlexComponent justifyContent='center'>
                            <div style={{ width: '100%' }}>
                                <div style={{ width: '100%', textAlign: 'start', margin: '0.5rem' }}>
                                    <label htmlFor="specific_area_interested_in" >
                                        <p style={{ fontWeight: 'bold' }}>Priority Areas</p>
                                    </label>
                                </div>

                                {formData.specific_area_interested_in?.length > 1 && <div className='inputContainerDiv' style={{ width: '60%', textAlign: 'start', margin: '0.5rem' }}>
                                    <label htmlFor="specific_area_interested_in" >
                                        <p >Please Select one area as a priority</p>
                                    </label>
                                </div>}

                                <div className='inputContainerDiv' style={{ width: '50%', background: 'white', padding: formData?.specific_area_interested_in.length > 0 ? '0.5rem' : '', borderRadius: '10px', margin: '0.5rem' }}>

                                    {formData?.specific_area_interested_in?.map((area, index) => {
                                        return (
                                            <FlexComponent justifyContent='start' gap='0.75rem' key={index} style={{ margin: '1rem' }}>
                                                <FlexComponent justifyContent='start' gap='0.75rem' key={index} style={{ marginTop: '0.5rem' }}>
                                                    {formData.specific_area_interested_in?.length > 1 &&
                                                        <Checkbox
                                                            type="checkbox"
                                                            id="specific_area_interested_in"
                                                            name="specific_area_interested_in"
                                                            value={area.priority}
                                                            checked={area.priority}
                                                            onChange={(event) => {
                                                                formData.specific_area_interested_in?.map((option, ind) => {
                                                                    if (ind == index) {
                                                                        setFormData((prevState) => ({
                                                                            ...prevState,
                                                                            specific_area_interested_in: prevState.specific_area_interested_in.map((item, i) =>
                                                                                i === ind ? { ...item, priority: true } : item
                                                                            )
                                                                        }));
                                                                    } else {
                                                                        setFormData((prevState) => ({
                                                                            ...prevState,
                                                                            specific_area_interested_in: prevState.specific_area_interested_in.map((item, i) =>
                                                                                i === ind ? { ...item, priority: false } : item
                                                                            )
                                                                        }));
                                                                    }
                                                                })
                                                            }}
                                                        />}
                                                    <label htmlFor={area.priority} className="ml-2">{area.city}{area.city && ','} {area.state} </label>
                                                </FlexComponent>
                                                <Button type='button' style={{ margin: '0 1rem', padding: '.5rem' }} onClick={() => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        specific_area_interested_in: prevState.specific_area_interested_in.filter((_, i) => i !== index)
                                                    }));
                                                    if (formData.specific_area_interested_in.length != 1 && formData.specific_area_interested_in[index].priority) {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            specific_area_interested_in: prevState.specific_area_interested_in.map((item, i) =>
                                                                i === 0 ? { ...item, priority: true } : item
                                                            )
                                                        }));
                                                    }
                                                }}><i className="pi pi-trash" style={{ fontSize: '1rem' }}></i></Button>
                                            </FlexComponent >
                                        );
                                    })}


                                </div>
                                <FlexComponent className='inputContainer' gap='1rem' alignItems='start' justifyContent='start'>
                                    <div className='inputContainerDiv' style={{ width: '45%', margin: '0.5rem 0.5rem' }}>
                                        <Dropdown
                                            id="state"
                                            value={areaState}
                                            options={State.getStatesOfCountry('US')?.map((state) => ({
                                                label: state.name,
                                                value: state.isoCode,
                                            })) || []}
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                setAreaState(e.value)
                                            }}
                                            placeholder="Select State"
                                        />

                                    </div>
                                    <div className='inputContainerDiv' style={{ width: '45%', margin: '0.5rem 0.5rem' }}>
                                        {City.getCitiesOfState('US', areaState).length > 0 ?
                                            <Dropdown
                                                id='city'
                                                value={areaCity}
                                                options={City.getCitiesOfState('US', areaState)?.map((city) => ({
                                                    label: city.name,
                                                    value: city.name,
                                                }))}
                                                style={{ width: '100%' }}
                                                onChange={(e) => {
                                                    setAreaCity(e.value)
                                                }}
                                                placeholder='Select City'
                                            /> :
                                            // <Field placeHolder='Enter City' type="text" id="city" name="city" value={formData.city} onChange={handleInputChange}
                                            //     as={InputText} style={{ width: '100%' }} />
                                            <InputText style={{ width: '100%' }} id="areaCity" defaultValue={areaCity} onChange={(e) => {
                                                setAreaCity(e.target.value)
                                            }} />
                                        }
                                    </div>
                                    <div style={{ textAlign: 'center', margin: '0.5rem 0.5rem' }}>
                                        <Button disabled={areaState ? false : true} type='button' onClick={handleAddAreas}>Add</Button>
                                    </div>
                                </FlexComponent>
                            </div>
                        </FlexComponent>
                        <div style={{ position: 'sticky', bottom: '0', background: '#283138' }}>
                            <FlexComponent justifyContent='center' gap='1rem' style={{ marginTop: '1rem', padding: "1rem" }}>
                                <Button type='button' label="Save" className="p-button-success" onClick={() => handleEditSave()} />
                                <Button type='button' label="Cancel" className="p-button-secondary" onClick={handleEditCancel} />
                            </FlexComponent>
                        </div>
                    </form>
                </div>
            </Dialog>

        </FlexComponent>
    )
}

export default ShoppingFor