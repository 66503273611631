import React, { useState } from 'react';
import FlexComponent from '../FlexComponent';
import { Avatar } from 'primereact/avatar';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from '../../utils/axios';
import { UpdateCustomAppLead, deleteProfileData } from '../../utils/urls';
import { Dropdown } from 'primereact/dropdown';
import { Field } from 'formik';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import Swal from 'sweetalert2';

function WorkExperience({ data, setIsSubmit, formId, userId, toast }) {
    function changeAvatarColor(type) {
        if (!type) return '';
        if (type.includes('W2')) {
            return '#F141D0';
        } else if (type === '1099 Independent Contractor') {
            return '#418BCC';
        } else {
            return '#EB4243';
        }
    }

    function avatarTitle(type) {
        if (!type) return '';
        if (type.includes('W2')) {
            return 'W2';
        } else if (type === '1099 Independent Contractor') {
            return '1099';
        } else if (type === 'Self Employed/Business Owner') {
            return 'SE';
        } else {
            return '';
        }
    }

    const [isEditOpen, setIsEditOpen] = useState(false);
    // const [editData, setEditData] = useState();
    const [label, setLabel] = useState("")
    const [otherTimeAtPosition, setOtherTimeAtPosition] = useState("")
    const [error, setError] = useState()

    const [formData, setFormData] = useState({
        work_title: '',
        company_name: '',
        work_type: '',
        time_at_position: '',
        income: '',
        id: ''
    });

    const handleAddClick = () => {
        setLabel("Add Work Experience")
        setFormData([])
        setIsEditOpen(true);
    }
    console.log(formData)
    const convertDateStringToDate = (dateString) => {
        if (!dateString) return null;
        const [year, month, day] = dateString.split('-');
        return new Date(year, month - 1, day); // month is 0-based in Date
    };

    const handleEditClick = (list) => {
        // setEditData(list);
        setLabel("Edit Work Experience")
        setFormData({
            work_title: list.work_title,
            company_name: list.company_name,
            work_type: list.type,
            time_at_position: list.time_at_position,
            income: list.unformatted_income,
            status: list.status,
            end_date: convertDateStringToDate(list.end_date),
            start_date: convertDateStringToDate(list.start_date),
            work_history_description: list.work_history_description,
            id: list.id
        });
        setIsEditOpen(true);
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const handleWorkTypeChange = (e) => {
        setFormData({
            ...formData,
            work_type: e.value
        });
    };

    // const handleDateChange = (e) => {
    //     const { value } = e;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [e.target.id]: value,
    //     }));
    // };

    const convertDateToDateString = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // month is 0-based in Date
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleEditSave = async () => {
        try {
            const errors = {};
            if (!formData.work_title?.trim()) errors.work_title = true;
            if (!formData.income) errors.income = true;
            if (!formData.time_at_position) errors.time_at_position = true;
            if (!formData.status && typeof formData.status !== 'boolean') errors.status = true;
            setError(errors);

            if (Object.keys(errors).length === 0) {
                setIsEditOpen(false);

                const updatedData = {
                    ...formData,
                    type: 'Work Experience',
                    time_at_position: formData.time_at_position === "Other" ? otherTimeAtPosition : formData.time_at_position,
                    start_date: convertDateToDateString(formData.start_date),
                    end_date: convertDateToDateString(formData.end_date),
                    userId: userId
                };
                console.log(updatedData)
                setIsEditOpen(false);
                console.log(updatedData)
                const url = `${UpdateCustomAppLead}${formId}`;
                const response = await axios.post(url, updatedData, {
                    headers: { 'Content-Type': 'application/json' }
                });
                if (response.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Updated', life: 3000 });
                    setIsSubmit(true);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                    setIsSubmit(true);
                }
            }

        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            setIsSubmit(true);
        }
    };



    const handleDeleteClick = async (id) => {
        try {
            Swal.fire({
                title: 'Are you sure to Delete the Work Experience?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const updatedData = {
                        id: id,
                        type: 'Work Experience',
                        userId: userId
                    };
                    console.log(updatedData)
                    const url = `${deleteProfileData}${formId}`;
                    const response = await axios.post(url, updatedData, {
                        headers: { 'Content-Type': 'application/json' }
                    });
                    if (response.status === 200) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Deleted', life: 3000 });
                        setIsSubmit(true);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                        setIsSubmit(true);
                    }
                }
            })
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            setIsSubmit(true);
        }
    }

    const handleEditCancel = () => {
        setError('')
        setIsEditOpen(false);
    };

    return (
        <div style={{ width: '100%', borderRadius: '1rem' }} className='workExperienceContainer' >
            <div style={{ textAlign: 'start', width: '100%', padding: '2rem' }}>
                <p style={{ fontWeight: '600' }}>Work Experience</p>
            </div>
            {data?.map((list, index) => (
                <FlexComponent key={index} flexDirection="column" style={{ textAlign: 'center', padding: '0 2rem' }}>
                    <FlexComponent alignItems="start" justifyContent="start" gap="1rem" style={{ textAlign: 'start' }}>
                        <div style={{ width: "20%" }}>
                            <Avatar
                                style={{ width: '4.5rem', color: 'white', background: changeAvatarColor(list?.type) }}
                                label={avatarTitle(list?.type)}
                                size="xlarge"
                            />
                        </div>
                        <div style={{ width: '65%' }}>
                            <p>{list?.work_title}</p>
                            <p>{list?.type}</p>
                            <p style={{ color: '#8C8C8C' }}>{list?.time_at_position}</p>
                            <p>
                                <span style={{ fontSize: '22px', fontWeight: 'bold' }}>{list?.income}</span>
                                <span style={{ marginLeft: '.3rem' }}>Yearly</span>
                            </p>
                        </div>
                        <div style={{ width: "15%" }} >
                            <Button
                                label="Edit"
                                style={{
                                    borderRadius: '25px',
                                    fontSize: '16px',
                                    padding: '5px 20px',
                                    border: '1px solid #E0E4EC',
                                    background: 'white',
                                    color: '#8C8C8C'
                                }}
                                onClick={() => {
                                    handleEditClick(list);
                                }}
                            />
                            {data.length > 1 && <Button
                                label="Delete"
                                style={{
                                    borderRadius: '25px',
                                    fontSize: '15px',
                                    padding: '5px 10px',
                                    border: '1px solid #E0E4EC',
                                    background: 'white',
                                    color: '#8C8C8C',
                                    marginTop: ".5rem"
                                }}
                                onClick={() => {
                                    handleDeleteClick(list.id)
                                }}
                            />}
                        </div>

                    </FlexComponent>
                    <Divider />
                </FlexComponent>
            ))}
            <Dialog className="profileEditForm" header={label} visible={isEditOpen} onHide={handleEditCancel}>
                <div  >
                    <form onSubmit={handleEditSave}>
                        <FlexComponent className="inputContainer" gap="1rem">
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="work_title" style={{ fontWeight: "bold", color: Boolean(error?.work_title) ? 'red' : '' }} >Title *</label> <br />
                                <InputText
                                    style={{ width: '100%' }}
                                    id="work_title"
                                    value={formData?.work_title}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="company_name" style={{ fontWeight: "bold" }} >Company Name</label> <br />
                                <InputText
                                    style={{ width: '100%' }}
                                    id="company_name"
                                    value={formData?.company_name}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </FlexComponent>
                        <FlexComponent className="inputContainer" gap="1rem">
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="work_type" style={{ fontWeight: "bold" }}>Work Type </label> <br />
                                <Dropdown
                                    id="work_type"
                                    value={formData?.work_type}
                                    options={[
                                        { label: 'Salary - W2 Employee', value: 'Salary - W2 Employee' },
                                        { label: 'Hourly - W2 Employee', value: 'Hourly - W2 Employee' },
                                        { label: '1099 Independent Contractor', value: '1099 Independent Contractor' },
                                        { label: 'Self Employed/Business Owner', value: 'Self Employed/Business Owner' }
                                    ]}
                                    style={{ width: '100%' }}
                                    onChange={handleWorkTypeChange}
                                    placeholder="Select Work Type"
                                />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="time_at_position" style={{ fontWeight: "bold", color: Boolean(error?.time_at_position) ? 'red' : '' }}>Time at Position *</label> <br />

                                {['1 year or less', '2 years', 'Other'].map((option) => {
                                    return (
                                        <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                            {/* <Field type="radio" id={option} name="time_at_position" value={option} as={RadioButton} /> */}
                                            <RadioButton inputId={option} name="time_at_position" value={option} onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    time_at_position: e.value
                                                });
                                            }} checked={formData?.time_at_position === option} />
                                            <label htmlFor={option} className="ml-2">{option}</label>

                                        </FlexComponent >
                                    );
                                })}
                                {(( formData.time_at_position === "Other") || (formData.time_at_position && formData.time_at_position !== '1 year or less' && formData.time_at_position !== '2 years')) && <InputText
                                    style={{ width: '100%', marginTop: "1rem" }}
                                    id="otherTimeAtPosition"
                                    value={otherTimeAtPosition || formData.time_at_position}
                                    onChange={(e) => {
                                        setOtherTimeAtPosition(e.target.value)
                                    }}
                                />}
                            </div>
                        </FlexComponent>
                        <FlexComponent className="inputContainer" gap="1rem">
                            <div style={{ textAlign: 'start', width: '50%', margin: '0.5rem' }}>
                                <label htmlFor="income" style={{ fontWeight: "bold", color: Boolean(error?.income) ? 'red' : '' }}>Income *</label> <br />
                                <InputText
                                    style={{ width: '100%' }}
                                    id="income"
                                    value={formData?.income}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='inputContainerDiv' style={{ textAlign: 'start', width: '50%', margin: '0.5rem' }}>
                                <label style={{
                                    fontWeight: 'bold',
                                    color: Boolean(error?.status) ? 'red' : ''
                                }} htmlFor="status" >Status *</label> <br />
                                {[{ label: "Currently working in this role", value: true }, { label: "I used to work in this role", value: false }].map((option) => {
                                    return (
                                        <FlexComponent justifyContent='start' gap='0.75rem' key={option.label} style={{ marginTop: '0.5rem' }}>
                                            {/* <Field type="radio" id={option.label} name={`work_history[${workIndex}].status`} value={option.value} as={RadioButton} /> */}
                                            <RadioButton inputId={option} name="status" value={option.value} onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    status: e.value
                                                });
                                            }} checked={formData?.status === option.value} />
                                            <label htmlFor={option.label} className="ml-2">{option.label}</label>

                                        </FlexComponent >
                                    );
                                })}
                            </div>
                        </FlexComponent>
                        <FlexComponent className="inputContainer" gap="1rem">
                            <div style={{ textAlign: 'start', width: '50%', margin: '0.5rem' }}>
                                <label htmlFor="start_date" style={{ fontWeight: "bold" }} >Start Date</label> <br />
                                <Calendar inputId="start_date" dateFormat="mm/dd/yy" value={formData.start_date}
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            start_date: e.value,
                                        }));
                                    }} />
                            </div>
                            <div style={{ textAlign: 'start', width: '50%', margin: '0.5rem' }}>
                                <label htmlFor="end_date" style={{ fontWeight: "bold" }}>End Date</label> <br />
                                <Calendar inputId="end_date" dateFormat="mm/dd/yy" value={formData.end_date}
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            end_date: e.value,
                                        }));
                                    }} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className="inputContainer" gap="1rem">
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="work_history_description" style={{ fontWeight: "bold" }}>Work History Description</label> <br />
                                <InputTextarea rows={4} type="text" id="work_history_description" name="work_history_description" style={{ width: '100%' }}
                                value={formData.work_history_description} onChange={handleInputChange}
                                    placeholder="" />
                            </div>
                        </FlexComponent>
                        <div style={{ position: 'sticky', bottom: '0' }}>
                            <FlexComponent justifyContent="center" gap="1rem" style={{ marginTop: '1rem', padding: "1rem" }}>
                                <Button type="button" label="Save" className="p-button-success" onClick={handleEditSave} />
                                <Button type="button" label="Cancel" className="p-button-secondary" onClick={handleEditCancel} />
                            </FlexComponent>
                        </div>
                    </form>
                </div>
            </Dialog>
            <FlexComponent flexDirection='column' style={{ borderRadius: '1rem', gap: '1.25rem', textAlign: 'center',  padding: '2rem' }}>
                <Button onClick={() => {
                    handleAddClick()
                }} >Add Work Experience</Button>
            </FlexComponent>
        </div>
    );
}

export default WorkExperience;
