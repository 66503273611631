import React, { useEffect, useRef, useState } from 'react'
import FlexComponent from '../FlexComponent'
import { Avatar } from 'primereact/avatar'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import axios from '../../utils/axios'
import { UpdateCustomAppLead, deleteProfileData } from '../../utils/urls'
import { RadioButton } from 'primereact/radiobutton'
import { InputTextarea } from 'primereact/inputtextarea'
import Swal from 'sweetalert2'
import './profile.css'


function Address({ data, setIsSubmit, formId, userId, toast }) {
    function changeAvatarColor(type) {
        if (!type) return ''
        if (type.includes("W2")) {
            return '#F141D0';
        } else if (type === "1099 Independent Contractor") {
            return '#418BCC';
        } else {
            return '#EB4243'
        }
    }

    function avatarTitle(type) {
        if (!type) return ''
        if (type.includes("W2")) {
            return 'W2';
        } else if (type === "1099 Independent Contractor") {
            return '1099';
        } else if (type === "Self Employed/Business Owner") {
            return 'SE'
        } else {
            return ''
        }
    }

    const [isEditOpen, setIsEditOpen] = useState(false);
    // const [editData, setEditData] = useState()
    const [loading, setLoading] = useState(false)
    const [label, setLabel] = useState("")
    const [error, setError] = useState()
    const [formData, setFormData] = useState({
        street_address: '',
        state: '',
        city: '',
        zip: '',
        home_status: '',
        status: '',
        notes_about_address: '',
        id: ''
    })


    const handleAddClick = () => {
        setLabel("Add Address")
        setFormData([])
        setIsEditOpen(true);
    }

    const handleEditClick = (list) => {
        // setEditData(list)
        setLabel("Edit Address")
        setFormData({
            street_address: list.street_address,
            state: list.state,
            city: list.city,
            zip: list.zip,
            home_status: list.home_status,
            status: list.status,
            notes_about_address: list.notes_about_address,
            id: list.id
        });
        setIsEditOpen(true);
    };

    const handleDeleteClick = async (id) => {
        try {
            Swal.fire({
                title: 'Are you sure to Delete the Address?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const updatedData = {
                        id: id,
                        type: 'Address',
                        userId: userId
                    };
                    console.log(updatedData)
                    const url = `${deleteProfileData}${formId}`;
                    const response = await axios.post(url, updatedData, {
                        headers: { 'Content-Type': 'application/json' }
                    });
                    if (response.status === 200) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Deleted', life: 3000 });
                        setIsSubmit(true);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                        setIsSubmit(true);
                    }
                    
                }
            })
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            setIsSubmit(true);
        }
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleEditSave = async () => {
        try {
            const errors = {};
            if (!formData.street_address?.trim()) errors.street_address = true;
            if (!formData.state) errors.state = true;
            if (!formData.city) errors.city = true;
            if (!formData.zip) errors.zip = true;
            if (!formData.home_status) errors.home_status = true;
            if (!formData.status && typeof formData.status !== 'boolean') errors.status = true;
            setError(errors);

            if (Object.keys(errors).length === 0) {
                setIsEditOpen(false);
                const updatedData = {
                    ...formData,
                    type: "Address",
                    userId: userId
                };
                console.log(updatedData);
                setIsEditOpen(false);
                console.log('Submit ')
                const url = `${UpdateCustomAppLead}${formId}`
                const response = await axios.post(url, updatedData, {
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Updated', life: 3000 });
                    setIsSubmit(true)
                    // onSubmitProps.resetForm();
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                    setIsSubmit(true)
                }
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            setIsSubmit(true)
        }
    };

    const handleEditCancel = () => {
        setError('')
        setIsEditOpen(false);
    };

    const autoCompleteRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        if (window.google && window.google.maps) {
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
                types: ['geocode'],
                componentRestrictions: { country: "us" },
            });

            autoCompleteRef.current.addListener('place_changed', () => {
                const place = autoCompleteRef.current.getPlace();
                // console.log(place)
                // handleChange(`address_history[${addressIndex}].street_address`)(place.formatted_address)

                setFormData((prev) => ({
                    ...prev,
                    street_address: place.formatted_address
                }))

                const addressComponents = place.address_components;

                let address = {
                    city: '',
                    state: '',
                    postalCode: '',
                };

                addressComponents.forEach(component => {
                    const types = component.types;
                    if (types.includes("locality")) {
                        address.city = component.long_name;
                    }
                    if (types.includes("administrative_area_level_1")) {
                        address.state = component.long_name;
                    }
                    if (types.includes("postal_code")) {
                        address.postalCode = component.long_name;
                    }
                });
 
                setFormData((prev) => ({
                    ...prev,
                    state: address.state,
                    city: address.city,
                    zip: address.postalCode,
                }))
            });
        }
    }, [loading]);
 
    return (
        <div className='profileAddressContainer' style={{ width: '100%', borderRadius: '1rem', boxShadow: "rgba(255, 255, 255, 0.25) -1px -2px 0px", background: "rgb(255 255 255 / 8%)", color: "white" }} >
            <div style={{ textAlign: 'start', width: '100%', padding: '2rem' }}>
                <p style={{ fontWeight: '600' }}>Address</p>
            </div>
            {data?.map((list, index) => (
                <FlexComponent key={index} flexDirection='column' style={{ gap: '1.25rem', textAlign: 'center', padding: '0 2rem' }}>
                    <FlexComponent alignItems='start' justifyContent='start' gap='1rem' style={{ textAlign: 'start' }}>
                        <div>
                            <Avatar style={{ width: '4.5rem', color: 'white' }} label={`H${index + 1}`} size="xlarge" />
                        </div>
                        <div style={{ width: '80%' }}>
                            {/* <p><span style={{ color: '#8C8C8C' }}>Country : </span>{list?.country}</p> */}
                            <p><span style={{ color: '#8C8C8C' }}>Street Address : </span>{list?.street_address || 'N/A'}</p>
                            <p><span style={{ color: '#8C8C8C' }}>State : </span>{list?.state || 'N/A'}</p>
                            <p><span style={{ color: '#8C8C8C' }}>City : </span>{list?.city || 'N/A'}</p>
                            <p><span style={{ color: '#8C8C8C' }}>Pin Code : </span>{list?.zip || 'N/A'}</p>
                            <p><span style={{ color: '#8C8C8C' }}>Home Status : </span>{list?.home_status || 'N/A'}</p>
                        </div>
                        <div style={{ width: "15%" }} >
                            <Button
                                label="Edit"
                                style={{
                                    borderRadius: '25px',
                                    fontSize: '16px',
                                    padding: '5px 20px',
                                    border: '1px solid #E0E4EC',
                                    background: 'white',
                                    color: '#8C8C8C'
                                }}
                                onClick={() => {
                                    setLoading(true)
                                    handleEditClick(list);
                                    setTimeout(()=>{
                                        setLoading(false)
                                    },1000)
                                }}
                            />
                            {data.length > 1 && <Button
                                label="Delete"
                                style={{
                                    borderRadius: '25px',
                                    fontSize: '15px',
                                    padding: '5px 10px',
                                    border: '1px solid #E0E4EC',
                                    background: 'white',
                                    color: '#8C8C8C',
                                    marginTop: ".5rem"
                                }}
                                onClick={() => {
                                    handleDeleteClick(list.id)
                                }}
                            />}
                        </div>
                    </FlexComponent>
                    <Divider />
                </FlexComponent>
            ))}
            <Dialog appendTo="self"  className='profileEditForm' header={label} visible={isEditOpen} onHide={() => handleEditCancel()}>
                <div >
                    <form onSubmit={handleEditSave} > 
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label style={{fontWeight: "bold", color: Boolean(error?.street_address) ? 'red' : '' }} htmlFor="street_address">Street Address</label> <br />
                                <InputText ref={inputRef} style={{ width: '100%' }} id="street_address" value={formData?.street_address} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label style={{fontWeight: "bold", color: Boolean(error?.state) ? 'red' : '' }} htmlFor="state">state</label> <br />
                                <InputText style={{ width: '100%' }} id="state" value={formData?.state} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label style={{fontWeight: "bold", color: Boolean(error?.city) ? 'red' : '' }} htmlFor="city">City</label> <br />
                                <InputText style={{ width: '100%' }} id="city" value={formData?.city} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label style={{fontWeight: "bold", color: Boolean(error?.zip) ? 'red' : '' }} htmlFor="zip">Pincode</label> <br />
                                <InputText style={{ width: '100%' }} id="zip" value={formData?.zip} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div className='inputContainerDiv' style={{ textAlign: 'start', width: '50%', margin: '0.5rem' }}>
                                <label style={{
                                    fontWeight: 'bold',
                                    color: Boolean(error?.status) ? 'red' : ''
                                }} htmlFor="status" >Is this your current address? *</label> <br />
                                {[{ label: "Yes", value: true }, { label: "No", value: false }].map((option) => {
                                    return (
                                        <FlexComponent justifyContent='start' gap='0.75rem' key={option.label} style={{ marginTop: '0.5rem' }}>
                                            {/* <Field type="radio" id={option.label} name={`work_history[${workIndex}].status`} value={option.value} as={RadioButton} /> */}
                                            <RadioButton inputId={option} name="status" value={option.value} onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    status: e.value
                                                });
                                            }} checked={formData?.status === option.value} />
                                            <label htmlFor={option.label} className="ml-2">{option.label}</label>

                                        </FlexComponent >
                                    );
                                })}
                            </div>
                            <div className='inputContainerDiv' style={{ textAlign: 'start', width: '50%', margin: '0.5rem' }}>
                                <label style={{
                                    fontWeight: 'bold',
                                    color: Boolean(error?.home_status) ? 'red' : ''
                                }} htmlFor="status" >Home Status *</label> <br />
                                {["Renting", "Owning"].map((option) => {
                                    return (
                                        <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                            <RadioButton inputId={option} name="home_status" value={option} onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    home_status: e.value
                                                });
                                            }} checked={formData?.home_status === option} />
                                            <label htmlFor={option} className="ml-2">{option}</label>

                                        </FlexComponent >
                                    );
                                })}
                            </div>
                        </FlexComponent>
                        <FlexComponent className="inputContainer" gap="1rem">
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="notes_about_address" style={{ fontWeight: "bold" }}>Notes about Address</label> <br />
                                <InputTextarea rows={4} type="text" id="notes_about_address" name="notes_about_address" style={{ width: '100%' }}
                                    value={formData?.notes_about_address} onChange={handleInputChange}
                                    placeholder="" />
                            </div>
                        </FlexComponent>
                        <div style={{ position: 'sticky', bottom: '0' }}>
                            <FlexComponent justifyContent='center' gap='1rem' style={{ marginTop: '1rem', padding: "1rem" }}>
                            <Button type='button' label="Save" className="p-button-success" onClick={() => handleEditSave()} />
                            <Button type='button' label="Cancel" className="p-button-secondary" onClick={handleEditCancel} />
                        </FlexComponent>
                        </div>
                    </form>
                </div>
            </Dialog>
            <FlexComponent flexDirection='column' style={{ borderRadius: '1rem', gap: '1.25rem', textAlign: 'center', padding: '2rem' }}>
                <Button onClick={() => {
                    setLoading(true)
                    handleAddClick()
                    setTimeout(()=>{
                        setLoading(false)
                    },1000)
                }} >Add Address</Button>
            </FlexComponent>
        </div>
    )
}

export default Address

