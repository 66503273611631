import React, { useEffect, useRef, useState } from 'react'
import GetStarted from '../../components/form/GetStarted'
import FlexComponent from '../../components/FlexComponent'
import './form.css'
import { Formik } from 'formik'
import * as yup from "yup";
import { Button } from 'primereact/button'
import WorkHistory from '../../components/form/WorkHistory'
import HowMuchMoneySaved from '../../components/form/HowMuchMoneySaved'
import YourCredit from '../../components/form/YourCredit'
import AddressHistory from '../../components/form/AddressHistory'
import ContactInformation from '../../components/form/ContactInformation'
import WhatTypeOfHome from '../../components/form/WhatTypeOfHome'
import CoSignerInfo from '../../components/form/CoSignerInfo'
import InternalDetails from '../../components/form/InternalDetails'
import axios from '../../utils/axios'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { verifyToken, form_submit, getAvailableUsers, shortFormSubmission } from '../../utils/urls'
import jwt_decode from "jwt-decode";
import { setUser } from '../../utils/userSlice'
import { RefreshToken } from '../../utils/refreshToken'
import Swal from 'sweetalert2'
import { Toast } from 'primereact/toast'
import { Nav } from '../../shared/Nav'
import { get_form_initials } from '../../utils/urls';
import logo from '../../Images/homes_sale_logo.png'
import { Avatar } from 'primereact/avatar'
import ShortFormComponent from '../../components/form/ShortFormComponent'


const formSchema = yup.object().shape({
    firstName: yup.string().trim().required("required"),
    lastName: yup.string().trim().required("required"),
    language: yup.string().required("required"),
    // work_history: yup.array().min(1, 'work history is Required').required('This field is required'),
    // address_history: yup.array().min(1, 'Address is Required').required('This field is required'),
    // title: yup.string().required("required"),
    // yearly_income: yup.string().required("required"),
    // time_at_position: yup.string().required("required"),
    // total_amount_saved: yup.number().required("required"),
    // estimated_credit_score: yup.number().required("required"),
    cell_phone_number: yup.string()
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Cell phone number must be in the format (xxx) xxx-xxx')
        .required('Cell phone number is required'),
    email: yup.string().email("invalid email").required("required"),
    // do_you_have_a_cosigner: yup.string().required("required"),
    lead_source: yup.string().required("required"),
    which_homes_for_sale_region: yup.string().required("required"),
    application_pipeline: yup.string().required("required"),
    application_by: yup.string().required("required"),
    entry_type: yup.string().required("required"),
});



function ShortForm() {

    const [locationData, setLocationData] = useState();
    const [ghlUserId, setGhlUserId] = useState(null);
    const toast = useRef(null);
    const navigate = useNavigate()
    const location = useLocation();
    const isSuperuser = useSelector((state) => state.user.isSuperuser);
    const image = useSelector((state) => state.user.image);
    const userLable = useSelector((state) => state.user.userLable);
    const userName = useSelector((state) => state.user.userName);
    const userId = useSelector((state) => state.user.userId);
    const is_leader = useSelector((state) => state.user.is_leader);
    const [scrolling, setScrolling] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [languageSelect, setLanguageSelect] = useState("")
    const [availableUsers, setAvailableUsers] = useState([])

    useEffect(() => {
        const authTokens = JSON.parse(localStorage.getItem('authTokens'))
        const refresh = authTokens?.refresh
        if (refresh) {
            const body = JSON.stringify({
                token: refresh,
            });
            axios
                .post(verifyToken, body, {
                    headers: { "Content-Type": "application/json" },
                })
                .then((response) => {
                    if (response.status === 200) {
                        const access = authTokens?.access
                        const decodedToken = jwt_decode(access)

                        dispatch(setUser({ userId: decodedToken.user_id, isSuperuser: decodedToken.is_superuser, userLable: decodedToken.userLable, image: decodedToken.image, userName: decodedToken.userName, ghlUserId: decodedToken.ghlUserId, is_leader: decodedToken.is_leader }));
                        localStorage.setItem("prevUrl", window.location.pathname);
                        // const superUser = decodedToken.is_superuser
                        // if (superUser) {
                        //     dispatch(setUser({ userId: decodedToken.user_id, isSuperuser: decodedToken.is_superuser }));
                        //     localStorage.setItem("prevUrl", window.location.pathname);
                        // }
                        // else {
                        //     navigate('/')
                        // }
                    }
                })
                .catch((err) => {
                    localStorage.removeItem('authTokens');
                    localStorage.removeItem('prevUrl');
                    dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
                    // dispatch(change({ id: null ,username: null, image: null }));
                    // setIsLoggedIn(false)
                    navigate('/login')
                });
        } else {
            localStorage.removeItem('authTokens');
            localStorage.removeItem('prevUrl');
            dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
            // dispatch(change({ id: null ,username: null, image: null }));
            // setIsLoggedIn(false)
            navigate('/login')
        }
    }, [isSuperuser])

    useEffect(() => {
        const intervalId = setInterval(async () => {
            await RefreshToken();
        }, 3600000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure to log out?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('authTokens');
                localStorage.removeItem('prevUrl');
                // dispatch(change({ username: null, image: null }));
                navigate('/login')
            }
        })
    }
    function changeIncomeColor(values) {
        try {
            // const income = parseInt(applicantIncome?.replace(/\D/g, ''));
            // console.log(income)
            // if (isNaN(income)) return '';
            const applicantIncome = values.work_history?.find((work) => work.status)?.yearly_income
            if (applicantIncome) {
                if (applicantIncome >= 80000) {
                    return '#2FC623';
                } else if (applicantIncome < 80000 && applicantIncome >= 65000) {
                    return 'orange';
                } else {
                    return '#EB4243'
                }

            }

        } catch (error) {
            return ''
        }
    }

    function changeCreditColor(applicantCredit) {
        try {
            const credit = parseInt(applicantCredit);
            // console.log(credit)
            if (isNaN(credit)) return '';
            if (credit >= 620) {
                return '#2FC623';
            } else if (credit < 620 && credit >= 500) {
                return '#F7DF01';
            } else {
                return 'red'
            }

        } catch (error) {
            return ''
        }
    }

    function changeAvailableColor(cashAvailable) {
        try {
            // console.log(cashAvailable)
            // const amount = parseInt(cashAvailable?.replace(/\D/g, ''));
            if (isNaN(cashAvailable)) return '';
            if (cashAvailable >= 45000) {
                return '#2FC623';
            } else if (cashAvailable < 45000 && cashAvailable >= 20000) {
                return '#FF9A00';
            } else if (cashAvailable) {
                return 'red'
            }

        } catch (error) {
            return ''
        }
    }

    const initialValues = {
        // I can definitely help. Let's get started!
        firstName: "",
        middleName: "",
        lastName: "",
        language: "",
        otherLanguage: "",

        cell_phone_number: "",
        email: "",
        // Internal Details
        lead_source: "Homes For Sale Lead",
        other_lead_source: "",
        // name_of_lead: "",
        // cell_number_of_lead: "",
        application_pipeline: "",
        application_by: "",
        which_homes_for_sale_region: "",
        entry_type: ""
    };

    const formatDate = (date) => {
        if (!date) return ''
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleClick = (event, id) => {
        event.preventDefault();
        let element = document.getElementById(id)
            ;
        if (!element && id === 'cosignerId') {
            element = document.getElementById('cosignerQuestionId')
        }
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            const elementRect = element.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.pageYOffset;
            window.scrollTo({ top: absoluteElementTop - 120, behavior: 'smooth' });
        }
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            setLoading(true);
            console.log('Submit requested')
            // await submitForm(values, onSubmitProps);
            const { otherLanguage, other_lead_source, ...data } = values
            // data.form_name = "New App"
            data.language = data.language === "Other" ? otherLanguage : data.language
            data.lead_source = data.lead_source === "Other" ? other_lead_source : data.lead_source          
            data.cell_phone_number = parseInt(data.cell_phone_number?.replace(/\D/g, '')) || "";
            
            // setTimeout(() => {
            //     setLoading(false);
            // }, 1000);
            console.log(data)
            const response = await axios.post(shortFormSubmission, data, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Form Submitted Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setLoading(false)
                onSubmitProps.resetForm();
            } else {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Some Error Happened",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setLoading(false)
            }
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Some Error Happened",
                showConfirmButton: false,
                timer: 1500,
            });
            setLoading(false)
        }
    };

    // useEffect(() => {
    //     const getLocationList = async () => {
    //         const list = await axios.get('https://backendapi.homesforsalemedia.com/api/get_form_initials/', {
    //             headers: { 'Content-Type': 'application/json' },
    //         })
    //         // console.log(list.data)
    //         const data = list.data
    //         const mergedData = [
    //             data.default,
    //             ...data.location_user_data
    //         ];
    //         console.log(mergedData)
    //         setLocationData(mergedData)
    //     }
    //     getLocationList()
    // }, [])

    const getLocationList = async () => {
        if (userId) {
            try {
                const response = await axios.get(`${get_form_initials}?userId=${userId}`, {
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.status === 200) {
                    const data = response.data;
                    const mergedData = [
                        // data.default,
                        ...data.location_user_data
                    ];
                    console.log(mergedData);
                    setLocationData(mergedData);
                    setGhlUserId(data.ghl_user_id)
                }
            } catch (error) {
                // Handle errors here
                console.error('Error fetching location list:', error);
            }
        }
    };

    const getAvailableUsersList = async () => {
        if (languageSelect) {
            try {
                const response = await axios.get(`${getAvailableUsers}?type=Live Assignment&language=${languageSelect}`, {
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.status === 200) {
                    const data = response.data;
                    console.log(data)
                    setAvailableUsers(data)
                }
            } catch (error) {
                // Handle errors here
                console.error('Error fetching location list:', error);
            }
        }
    };

    useEffect(() => {
        getLocationList();
    }, [get_form_initials, userId]);

    useEffect(() => {
        getAvailableUsersList()
    }, [languageSelect])

    function getCurrentYearlyIncome(values) {
        try {
            const findIncome = values.work_history?.find((work) => work.status)
            if (findIncome) {
                return findIncome.yearly_income
            }

        } catch (error) {
            return ''
        }
    }

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    function formatNumber(number) {
        // Convert the input to a number
        if (number) {
            const num = parseInt(number, 10);

            if (isNaN(num)) {
                throw new Error('Invalid number');
            }

            if (num >= 1000 && num < 1000000) {
                return (num / 1000).toFixed(1) + 'k';
            } else if (num >= 1000000 && num < 1000000000) {
                return (num / 1000000).toFixed(1) + 'M';
            } else if (num >= 1000000000) {
                return (num / 1000000000).toFixed(1) + 'B';
            } else {
                return num.toString();
            }
        }
    }

    return (
        <div>
            <Toast ref={toast} />
            <header className={scrolling ? 'sticky' : ''}>
                <Nav handleLogout={handleLogout} isSuperuser={isSuperuser} userLable={userLable} image={image} userName={userName} is_leader={is_leader} />
            </header>
            <div style={{ padding: "2.5rem 0", background: "white", position: 'relative' }}>

                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={formSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        handleSubmit,
                        isSubmitting
                        // setFieldValue,
                        // resetForm,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <FlexComponent className="formDiv" justifyContent='end' alignItems='start' gap="1rem">
                                {/* <AutoComplete/> */}
                                {/* <img className="navigationClass" src={logo} style={{ width: '150px', height: '150px' }} alt="" /> */}
                                <FlexComponent className="inputContainer" flexDirection='column' style={{ zIndex: '1', background: '#F2F2F2', width: '70%', boxShadow: '0px 4px 10px 4px rgb(0 0 0 / 20%)', borderRadius: '15px' }}>
                                    <ShortFormComponent  setLanguageSelect={setLanguageSelect} ghlUserId={ghlUserId} locationData={locationData} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
                                    
                                    {/* {Object.keys(errors).length > 0 && <span style={{color: 'red'}}>please fill the required fields</span>} */}
                                    <FlexComponent justifyContent="center" style={{ padding: "1rem" }}>
                                        <Button type="submit" style={{background: "black"}} label="Submit" icon="pi pi-check" loading={loading} disabled={isSubmitting} />
                                    </FlexComponent>
                                </FlexComponent>
                                <FlexComponent className="navigationClass" flexDirection="column" style={{ width: "20%", marginTop: ".2rem" }}>
                                    
                                
                                    {availableUsers.length > 0 && <div>
                                    <h3>Available Users</h3>
                                    {availableUsers?.map((user) => (
                                        <FlexComponent style={{ marginTop: ".5rem" }} justifyContent="start" >
                                            <Avatar image={user.image} size="small" label={<span style={{ padding: '5px' }}>{getInitials(user?.name)}</span>} shape="circle" style={{ marginRight: '8px' }} />
                                            {user.name}
                                        </FlexComponent>
                                    ))}
                                    </div>}
                                </FlexComponent>
                            </FlexComponent>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ShortForm