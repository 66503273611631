import React, { useState, useEffect } from 'react'
import './Auth.css'
// import { SignUpModeContext } from '../../../Context/SignupModeContext'
import { useLocation, useNavigate } from "react-router-dom"
import LoginForm from '../loginForm/LoginForm'
import MainPanel from '../../panels/MainPanel'
import image from '../../Images/neighborhood-bg.png'
// import loginImage from '../../Images/loginImage.svg'
import loginImageOne from '../../Images/loginImage1.jpg'
import FlexComponent from '../FlexComponent'
import breadcrumbsLogo from '../../Images/Breadcrumb_logo.png'

function Auth() {

  const [signUpState, setSignUpState] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();


  //   useEffect(() => {
  //     if (location.pathname === "/signup") {
  //       setSignUpState(true);
  //     } else if (location.pathname === "/login") {
  //       setSignUpState(false);
  //     }
  //   }, [location]);

  //   useEffect(() => {
  //     if (!isInitialRender) {
  //       if (!signUpState) {
  //         navigate("/login");
  //       } else {
  //         navigate("/signup");
  //       }
  //     } else {
  //       setIsInitialRender(false);
  //     }
  //   }, [signUpState]);


  return (
    <div className='loginPage' style={{background: "black"}} >
      {/* <div style={{backgroundImage : `url(${loginImage})`}} className={signUpState ? "container1 sign-up-mode": "container1"}>
      <div className="forms-container1">
        <div style={{height: '100%', background: 'white'}} className="signin-signup">
         <LoginForm/>
        </div>
      </div>
      <MainPanel/>
    </div> */}
      
      <FlexComponent className="desktopViewLogin" >
        <div style={{ height: '50%', width: "50%", marginTop: "5rem" }}>
          <LoginForm />
        </div>
        <div style={{ backgroundImage: `url(${loginImageOne})`, width: "50%", backgroundPosition: "top", backgroundSize: "cover" }} className={signUpState ? "container1 sign-up-mode" : "container1"}></div>
        {/* <div style={{width: "50%", overflow: "hidden"}} > */}
        {/* <img src={loginImage} style={{width: "50%", height: "100vh" }} alt="" /> */}
        {/* </div> */}
      </FlexComponent>

      <FlexComponent className="mobileViewLogin" justifyContent="center" style={{ height: "100vh" }} >
        <div style={{ height: '50%', width: "50%" }}>
          <LoginForm />
        </div>
      </FlexComponent>
      <div style={{ position: "absolute", height:"70px", boxShadow: "-1px -2px 0px #ffffff40",  borderRadius: "10px", top: 40, left: "8%", background: "rgba(255, 255, 255, 0.06)", width: "80vw", backdropFilter: "blur(2px)"  }} >
      </div>
         <div style={{position: "absolute", paddingLeft: "1rem", fontSize: "20px", filter: "blur(0px)", color: "white",  top: 60, left: "8%", }} >
          <img src={breadcrumbsLogo} style={{ height: "30px"}} alt="" />
          </div> 
          {/* <div style={{position: "absolute", paddingLeft: "1rem", fontSize: "20px", filter: "blur(0px)", color: "white",  top: 62, left: "8%", }} >
          breadcrumbs.</div>  */}
    </div>

  )
}

export default Auth