import React, { useRef, useState } from 'react'
import FlexComponent from '../../FlexComponent'
import { Avatar } from 'primereact/avatar'
import { Button } from 'primereact/button'
import CheckList from './CheckList';
import Activity from './Activity';
import { useNavigate } from 'react-router-dom';
import Description from './Description';
import Label from './Label';
import { Divider } from 'primereact/divider';
import CheckListContainer from './CheckListContainer';
import CustomField from './CustomField';
import Watcher from './Watcher';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import axios from '../../../utils/axios';
import { updateDueDate } from '../../../utils/urls';
import Members from './Members';


function changeIncomeColor(applicantIncome) {
    const income = parseInt(applicantIncome?.replace(/\D/g, ''));
    // console.log(income)
    if (isNaN(income)) return '';
    if (income >= 80) {
        return '#2FC623';
    } else if (income < 80 && income >= 65) {
        return 'orange';
    } else {
        return '#EB4243'
    }
}

function changeCreditColor(applicantCredit) {
    const credit = parseInt(applicantCredit);
    // console.log(credit)
    if (isNaN(credit)) return '';
    if (credit >= 620) {
        return '#2FC623';
    } else if (credit < 620 && credit >= 500) {
        return '#F7DF01';
    } else {
        return 'red'
    }
}

function changeAvailableColor(cashAvailable) {
    const amount = parseInt(cashAvailable?.replace(/\D/g, ''));
    // console.log(amount)
    if (isNaN(amount)) return '';
    if (amount >= 45) {
        return '#2FC623';
    } else if (amount < 45 && amount >= 20) {
        return '#FF9A00';
    } else {
        return 'red'
    }
}

function formatDateTime(inputDateTime) {
    if (!inputDateTime) return ''
    const [datePart, timePart, period] = inputDateTime?.split(' ');
    const [year, month, day] = datePart?.split('-');
    let [hour, minute] = timePart?.split(':');
    if (period === 'PM' && hour !== '12') {
        hour = parseInt(hour) + 12;
    } else if (period === 'AM' && hour === '12') {
        hour = '00';
    }
    const date = new Date(year, month - 1, day, hour, minute);
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const formattedDate = `${parseInt(day)} ${monthNames[date.getMonth()]}, ${year}`;
    let formattedHour = date.getHours();
    let formattedMinute = date.getMinutes().toString().padStart(2, '0');
    let ampm = formattedHour >= 12 ? 'pm' : 'am';
    formattedHour = formattedHour % 12 || 12; // Convert to 12-hour format
    formattedHour = formattedHour.toString().padStart(2, '0');

    const formattedTime = `${formattedHour}:${formattedMinute}${ampm}`;

    return `${formattedDate} | ${formattedTime}`;
}

function convertDate(utcDateString) {
    if (!utcDateString) return ""
    const utcDate = new Date(utcDateString);

    const dateOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    };

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };

    const formattedDate = utcDate.toLocaleDateString('en-GB', dateOptions);
    const formattedTime = utcDate.toLocaleTimeString('en-US', timeOptions);

    const formattedLocalDateTime = `${formattedDate} | ${formattedTime.toLowerCase()}`;

    console.log(formattedLocalDateTime);
    return formattedLocalDateTime

}

function AppPopup({ applicantData, allCustomFields, pipelineId = 1, usersList, userId, setBoardSections, getAllCustomFields, handleClose, toast }) {
    const navigate = useNavigate()
    const [isEditDueDate, setIsEditDueDate] = useState(false)
    const [datetime12h, setDateTime12h] = useState(new Date());
    const [savedDateTime, setSavedDateTime] = useState(applicantData?.due_date)
    const editDueDateOp = useRef(null);

    const redirectProfilePage = (contact_id, form_id) => {
        navigate(`/app/${contact_id}/${form_id}`)
    }

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    const handleUpdateDueDate = () => {
        const data = {
            form_id: applicantData.form_id,
            pipeline_id: pipelineId,
            due_date: datetime12h
        }
        console.log(data)
        setSavedDateTime(datetime12h)
        const Url = `${updateDueDate}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // setCommentLoad(false)
                    setBoardSections(prevSections => {
                        const updatedSections = { ...prevSections };
                        for (const sectionId in updatedSections) {
                            updatedSections[sectionId] = updatedSections[sectionId].map(item => {
                                if (item.form_id === applicantData.form_id) {
                                    return {
                                        ...item,
                                        due_date: datetime12h
                                    };
                                }
                                return item;
                            });
                        }
                        return updatedSections;
                    });
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })
    };

    return (
        <div >
            <FlexComponent justifyContent="end" alignItems="start" gap="3rem" className="applicantContainer">
                <FlexComponent className="applicantContainer" style={{ width: '70%' }} flexDirection="column" gap="1.5rem">
                    <FlexComponent className="applicantContainer" gap="1rem" >
                        <div className="applicantDivs" style={{ width: '40%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Name</div>
                            <div>{applicantData?.full_name}</div>
                        </div>
                        <div className="applicantDivs" style={{ width: '60%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Location</div>
                            <div>{applicantData?.location_name}</div>
                        </div>
                    </FlexComponent>
                    <FlexComponent gap="1rem" className="applicantContainer">
                        <div className="applicantDivs" style={{ width: '40%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Phone</div>
                            <div>{applicantData?.phone}</div>
                        </div>
                        <div className="applicantDivs" style={{ width: '60%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Email</div>
                            <div>{applicantData?.email}</div>
                        </div>
                    </FlexComponent>
                    <FlexComponent gap="1rem" className="applicantContainer">
                        <div className="applicantDivs" style={{ width: '40%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }} >Members</div>
                            <div style={{ marginTop: '0.5rem' }}>
                                <FlexComponent gap="0.1rem" justifyContent="start" alignItems="start">
                                    <Members members={applicantData.images} contactOwners={applicantData.user_owners} locationUsers={applicantData.location_available_users} setBoardSections={setBoardSections} contactId={applicantData.ghl_contact_id} formId={applicantData?.form_id} userId={userId} toast={toast} />
                                </FlexComponent>
                            </div>
                        </div>
                        <FlexComponent justifyContent='start' gap='1rem' style={{ width: '60%' }}>
                            <div >
                                <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeIncomeColor(applicantData?.income), fontSize: '24px' }}>{applicantData?.income}</p>
                                <p>Income</p>
                            </div>
                            <div>
                                <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeCreditColor(applicantData?.credit), fontSize: '24px' }}>{applicantData?.credit}</p>
                                <p>Credit</p>
                            </div>
                            <div>
                                <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeAvailableColor(applicantData?.savings), fontSize: '24px' }}>{applicantData?.savings}</p>
                                <p>Available</p>
                            </div>
                        </FlexComponent>
                    </FlexComponent>
                    <FlexComponent className="applicantContainer" gap="1rem" >
                        <div className="applicantDivs" style={{ width: '40%' }} >
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Application By</div>
                            <div>{applicantData?.app_application_by}</div>
                        </div>
                        <div className="applicantDivs" style={{ width: '60%' }}>
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Date and Time</div>
                            <div>{formatDateTime(applicantData?.created_date)}</div>
                        </div>

                    </FlexComponent>

                </FlexComponent >
                <FlexComponent className="applicantContainer" style={{ width: '35%' }} flexDirection="column" gap="1rem" alignItems="start">
                    <div className="applicantDivs" style={{ width: '60%' }}>
                        <div style={{ fontSize: '14px', color: '#B1B1B1' }}>Language</div>
                        <div>{applicantData?.language}</div>
                    </div>
                    <p>Status</p>
                    <Button onMouseDown={() => window.open(`/app/${applicantData?.ghl_contact_id}/${applicantData?.form_id}`, '_blank')} style={{ fontSize: '14px', background: 'white', color:"black", fontWeight:"bold", border: 'none', width: '180px' }}><i className="pi pi-book"></i> <span style={{ marginLeft: '0.5rem' }}></span> View Application</Button>
                    <Button onClick={() => window.open(`https://app.hfsarm.com/v2/location/${applicantData?.location_id}/contacts/detail/${applicantData?.ghl_contact_id}`, '_blank')} style={{ fontSize: '14px', fontWeight:"bold",  background: 'transparent', border: '1px solid grey', width: '180px' }}><i className="pi pi-external-link"></i> <span style={{ marginLeft: '0.5rem' }}>View Contact</span></Button>
                    <div className="applicantDivs" >
                        <FlexComponent justifyContent="start" gap="1rem" >
                            <div style={{ fontSize: '14px', color: '#B1B1B1' }} >Due Date</div>
                            <i style={{ cursor: "pointer" }} onClick={(e) => {
                                editDueDateOp.current.toggle(e)
                            }} className='pi pi-pencil'></i>
                        </FlexComponent>
                        {/* {isEditDueDate ? */}
                        <FlexComponent gap=".5rem">
                            <p >{convertDate(savedDateTime)}</p>
                            {/* <i style={{cursor:"pointer"}} onClick={(e) => {
                            editDueDateOp.current.toggle(e)
                            }} className='pi pi-pencil'></i> */}
                        </FlexComponent>
                        <OverlayPanel ref={editDueDateOp} style={{ background: '#1A2B53' }} >
                            <div>
                                <Calendar id="calendar-12h" inline value={datetime12h || new Date(applicantData?.due_date)} onChange={(e) => setDateTime12h(e.value)} showTime hourFormat="12" />
                                <div style={{ textAlign: "end", marginTop: ".5rem" }} >
                                    <Button style={{ padding: "0.5rem", border: "1px solid grey" }} onClick={(e) => {
                                        editDueDateOp.current.toggle(e)
                                        handleUpdateDueDate()
                                    }}>Save</Button></div>
                            </div>
                        </OverlayPanel>
                        {/* } */}
                    </div>
                </FlexComponent>
            </FlexComponent>
            <div style={{ marginTop: '2rem' }} >
                <FlexComponent className="applicantContainer">
                    <Label labels={applicantData.labels} setBoardSections={setBoardSections} formId={applicantData?.form_id} userId={userId} toast={toast} />
                    <Watcher toast={toast} watchers={applicantData?.watchers} formId={applicantData?.form_id} userId={userId} />
                </FlexComponent>
                <Divider />
                <Description description={applicantData?.description} usersList={usersList} formId={applicantData?.form_id} toast={toast} />
                <Divider />
                <CustomField toast={toast} applicantData={applicantData} fullName={applicantData?.full_name} setBoardSections={setBoardSections} formId={applicantData?.form_id} pipelineId={pipelineId} imagesList={applicantData?.images} customFields={applicantData?.customFields} getAllCustomFields={getAllCustomFields} allCustomFields={allCustomFields} />
                <Divider />
                <CheckListContainer userId={userId} usersList={usersList} pipelineId={pipelineId} salesUsersList={applicantData?.images} formId={applicantData?.form_id} toast={toast}  handleClose={handleClose} checkListsData={applicantData?.checklist_heading} />
                {/* <CheckList userId={userId} usersList={usersList} formId={applicantData?.form_id} toast={toast}  handleClose={handleClose} checkListsData={applicantData?.checklist} /> */}
                <Divider />
                {applicantData?.form_id && <Activity usersList={usersList} handleClose={handleClose} toast={toast} userId={userId} formId={applicantData?.form_id} comments={applicantData?.comments} />}
            </div>
        </div>
    )
}

export default AppPopup