import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import axios from '../../utils/axios'
import { UpdateCustomAppLead, deleteProfileData } from '../../utils/urls'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import Swal from 'sweetalert2';

function PropertyOwned({ data, setIsSubmit, formId, userId, toast }) {
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [label, setLabel] = useState("")
    // const [editData, setEditData] = useState()
    const [formData, setFormData] = useState({
        rental_income: '',
        notes: '',
        id: ''
    })

    const handleEditClick = (list) => {
        // setEditData(list)
        setLabel("Edit Property Owned")
        setFormData({
            rental_income: list.rental_income,
            notes: list.notes,
            id: list.id
        });
        setIsEditOpen(true);
    };


    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleDeleteClick = async (id) => {
        try {
            Swal.fire({
                title: 'Are you sure to Delete Property Owned?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const updatedData = {
                        id: id,
                        type: 'Property Owned',
                        userId: userId
                    };
                    console.log(updatedData)
                    const url = `${deleteProfileData}${formId}`;
                    const response = await axios.post(url, updatedData, {
                        headers: { 'Content-Type': 'application/json' }
                    });
                    if (response.status === 200) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Deleted', life: 3000 });
                        setIsSubmit(true);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                        setIsSubmit(true);
                    }
                }
            })
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            setIsSubmit(true);
        }
    }

    const handleEditSave = async () => {
        try {
            const updatedData = {
                ...formData,
                type: "Property Owned",
                userId: userId
            };
            console.log(updatedData);
            setIsEditOpen(false);
            console.log('Submit ')
            const url = `${UpdateCustomAppLead}${formId}`
            const response = await axios.post(url, updatedData, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Updated', life: 3000 });
                setIsSubmit(true)
                // onSubmitProps.resetForm();
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                setIsSubmit(true)
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            setIsSubmit(true)
        }
    };

    const handleEditCancel = () => {
        setIsEditOpen(false);
    };

    const handleAddClick = () => {
        setLabel("Add Property Owned")
        setFormData([])
        setIsEditOpen(true);
    }

    return (
        <FlexComponent flexDirection='column' style={{ borderRadius: '1rem', gap: '1.25rem', textAlign: 'center', padding: '2rem' }} className="propertyOwnedContainer">
            <div style={{ width: '100%', textAlign: 'start' }}>
                <p style={{ fontWeight: '600' }}>Property Owned</p>
            </div>

            {data?.length > 0 ? data?.map((list, index) => (
                <FlexComponent key={index} style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 1px", padding: "1rem", flexDirection: "column", gap: '2rem', alignItems: 'start' }}>
                    <FlexComponent justifyContent="end" >
                        <FlexComponent flexDirection="column" >
                            <div style={{ textAlign: 'start', width: '100%' }}>
                                <p style={{ color: '#8C8C8C' }}>Rental Income</p>
                                <p>{list?.rental_income || 'N/A'}</p>
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', marginTop: "1rem" }}>
                                <p style={{ color: '#8C8C8C' }}>Note</p>
                                <p>{list?.notes || 'N/A'}</p>
                            </div>
                        </FlexComponent>
                        <div style={{ width: "15%" }} >
                            <Button
                                label="Edit"
                                style={{
                                    borderRadius: '25px',
                                    fontSize: '16px',
                                    padding: '5px 20px',
                                    border: '1px solid #E0E4EC',
                                    background: 'white',
                                    color: '#8C8C8C'
                                }}
                                onClick={() => {
                                    handleEditClick(list);
                                }}
                            />
                            {data.length > 1 && <Button
                                label="Delete"
                                style={{
                                    borderRadius: '25px',
                                    fontSize: '15px',
                                    padding: '5px 10px',
                                    border: '1px solid #E0E4EC',
                                    background: 'white',
                                    color: '#8C8C8C',
                                    marginTop: ".5rem"
                                }}
                                onClick={() => {
                                    handleDeleteClick(list.id)
                                }}
                            />}
                        </div>
                    </FlexComponent>

                </FlexComponent>
            )) : <div>N/A</div>}
            <Dialog className='editForm' header={label} visible={isEditOpen} onHide={() => handleEditCancel()}>
                <div >
                    <form onSubmit={handleEditSave} >
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label style={{ fontWeight: "bold" }} htmlFor="rental_income">Rental Income</label> <br />
                                <InputText style={{ width: '100%' }} id="rental_income" defaultValue={formData?.rental_income} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label style={{ fontWeight: "bold" }} htmlFor="notes">notes</label> <br />
                                {/* <InputText style={{ width: '100%' }} id="notes" defaultValue={formData?.notes} onChange={handleInputChange} /> */}
                                <InputTextarea rows={4} type="text" id="notes" name="notes" style={{ width: '100%' }} defaultValue={formData?.notes} onChange={handleInputChange}
                                    placeholder="" />
                            </div>
                        </FlexComponent>

                        <FlexComponent justifyContent='center' gap='1rem' style={{ marginTop: '1rem' }}>
                            <Button type='button' label="Save" className="p-button-success" onClick={() => handleEditSave()} />
                            <Button type='button' label="Cancel" className="p-button-secondary" onClick={handleEditCancel} />
                        </FlexComponent>
                    </form>
                </div>
            </Dialog>
            <FlexComponent flexDirection='column' style={{ borderRadius: '1rem', gap: '1.25rem', textAlign: 'center', padding: '2rem' }}>
                <Button onClick={() => {
                    handleAddClick()
                }} >Add Property Owned</Button>
            </FlexComponent>
        </FlexComponent>
    )
}

export default PropertyOwned