import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { ErrorMessage, Field, FieldArray } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import './form.css'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { Country, State, City } from 'country-state-city';
import { InputMask } from 'primereact/inputmask'
import { Dialog } from 'primereact/dialog'

function CoSignerInfo({ values, errors, touched, handleBlur, handleChange }) {
    const [isEditOpen, setIsEditOpen] = useState(false); // State for popup visibility
    const [cosingerIndex, setCosingerIndex] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()


    const handleAddClick = () => {
        setLoading(true)
        values.cosigner_list.push({
            co_borrower_first_name: "",
            co_borrower_middle_name: "",
            co_borrower_last_name: "",
            cb_DOB: null,
            cb_credit_score: "",
            cb_title: "",
            cb_yearly_income: "",
            cb_time_at_position: "",
            cb_company_name: "",
            cb_employment_type: "",
            cb_status: "",
            cb_start_date: null,
            cb_end_date: null,
            cb_work_history_description: "",
            cosigner_street_address: "",
            cosginer_city: "",
            cosigner_state: "",
            cosigner_zip_code: "",
            cosigner_cell_phone_number: "",
            cosigner_email: "",
            cosigner_security_no: "",
        });

        //   handleChange('work_history')(values.work_history); 
        setCosingerIndex(values.cosigner_list.length - 1)
        setIsEdit(false);
        setIsDelete(false)
        setIsEditOpen(true);
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    };


    const handleEditClick = (index = 0) => {
        setCosingerIndex(index)
        setIsEditOpen(true);
    };

    // const handleEditSave = (e) => {
    //     e.preventDefault()
    //     setIsEditOpen(false);
    // };

    const handleAddCancel = () => {
        setIsEditOpen(false); // Close the popup on cancel
    };

    const handleSave = () => {
        // const cosignerEntry = values.cosigner_list[cosingerIndex];
        const errors = {};
        // if (!cosignerEntry.co_borrower_first_name?.trim()) errors.co_borrower_first_name = true;
        // if (!cosignerEntry.co_borrower_last_name?.trim()) errors.co_borrower_last_name = true;

        setError(errors);

        if (Object.keys(errors).length === 0) {
            setIsEditOpen(false);
        }
    }

    const formatDate = (date) => {
        if (!date) return ''
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <div style={{ width: '100%', padding: '1.5rem' }} id='cosignerId'>
            <div style={{ background: '#08113A' }}>
                <p style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.75rem', color: 'white' }}>Co-Signer Info</p>
            </div>

            {!loading && values?.cosigner_list?.map((cosigner, index) => (
                <FlexComponent key={index} style={{ background: 'white', margin: '1rem 0', padding: '1rem', borderRadius: '10px' }} gap='1rem'>
                    <FlexComponent alignItems='start' justifyContent='start' gap='1rem' style={{ textAlign: 'start' }}>
                        {/* <div>
                            <Avatar style={{width: '4.5rem', color:'white'}}  size="xlarge" />
                        </div> */}
                        <div style={{ width: '50%' }}>
                            <p>
                                <span style={{ color: '#8C8C8C' }}>Co-Borrower Name : </span><span>{`${cosigner?.co_borrower_first_name} ${cosigner?.co_borrower_middle_name} ${cosigner?.co_borrower_last_name}`}, </span>
                            </p>
                            <p><span style={{ color: '#8C8C8C' }}>DOB : </span><span >{formatDate(cosigner?.cb_DOB)}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>CB Credit Score : </span><span >{cosigner?.cb_credit_score}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>Co-Borrower ## : </span><span >{cosigner?.cosigner_security_no}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>CB Title : </span><span >{cosigner?.cb_title}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>CB Credit Score : </span><span >{cosigner?.cb_yearly_income}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>Time at Position : </span><span >{cosigner?.cb_time_at_position}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>Company Name : </span><span >{cosigner?.cb_company_name}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>Employment Type : </span><span >{cosigner?.cb_employment_type}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>Status : </span><span >{cosigner?.cb_status}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>Start Date : </span><span >{formatDate(cosigner?.cb_start_date)}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>End Date : </span><span >{formatDate(cosigner?.cb_end_date)}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>Work History Description : </span><span >{cosigner?.cb_work_history_description}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>Street Address : </span><span >{`${cosigner?.cosigner_state} ${cosigner?.cosginer_city} ${cosigner?.cosigner_zip_code}`}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>Cosigner Cell Phone Number : </span><span >{cosigner?.cosigner_cell_phone_number}</span></p>
                            <p><span style={{ color: '#8C8C8C' }}>Cosigner Email : </span><span >{cosigner?.cosigner_email}</span></p>
                            </div>
                    </FlexComponent>
                    <Button type='button' onClick={() => {
                        setCosingerIndex(index)
                        setIsEdit(true)
                        setIsDelete(true)
                        setIsEditOpen(true);
                    }}>Edit</Button>
                </FlexComponent>
            ))}
            <div style={{ textAlign: "center", margin: '1rem' }}>
                <Button type='button' label="Add Cosigner" style={{ borderRadius: '5px', fontSize: '16px', padding: '5px 20px', border: '1px solid #E0E4EC', background: 'white', color: '#8C8C8C' }} onClick={handleAddClick} />
            </div>

            <FieldArray name="cosigner_list">
                {(arrayHelpers) => (
                    <Dialog footer={<div style={{ position: 'sticky', bottom: '0', background: 'transparent' }}>
                    <FlexComponent gap='1rem' justifyContent={!isDelete ? 'end' : ''} style={{ padding: '1rem' }}>
                        {isDelete && <Button type='button' onClick={() => {
                            // if (!isEdit) {
                            arrayHelpers.remove(cosingerIndex);
                            setIsEdit(false)
                            setIsDelete(true)
                            // }
                            setIsEditOpen(false);
                        }}>Delete Cosigner Data</Button>}
                        <Button type='button' label={isEdit ? 'Close' : 'Save'} onClick={handleSave} />
                    </FlexComponent>
                </div>} className='cosignerForm formDiv' style={{ width: '65vw', background:"white", color:"black" }} header="Add Cosigner" visible={isEditOpen} onHide={() => {
                        if (!isEdit) {
                            arrayHelpers.remove(cosingerIndex);
                            setIsEdit(false)
                            setIsEditOpen(false);
                            setError('')
                        }
                        if (isEdit) {
                            handleSave()
                        }
                        setIsDelete(true)
                        // setIsEditOpen(false);
                    }}>
                        <div style={{ textAlign: 'start', padding: '1.5rem' }}>
                        <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                            <div className='inputContainerDiv' style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label style={{ fontWeight: 'bold' }}>Co-Borrower Name</label> <br />
                                <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                                    <div className='inputContainerDiv' style={{ width: "100%" }}>
                                        <Field type="text" id="co_borrower_first_name" name={`cosigner_list[${cosingerIndex}].co_borrower_first_name`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                                        <label htmlFor="co_borrower_first_name" >First</label>
                                    </div>
                                    <div className='inputContainerDiv' style={{ width: "100%" }}>
                                        <Field type="text" id="co_borrower_middle_name" name={`cosigner_list[${cosingerIndex}].co_borrower_middle_name`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                                        <label htmlFor="co_borrower_middle_name" >Middle</label>
                                    </div>
                                    <div className='inputContainerDiv' style={{ width: "100%" }}>
                                        <Field type="text" id="co_borrower_last_name" name={`cosigner_list[${cosingerIndex}].co_borrower_last_name`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                                        <label htmlFor="co_borrower_last_name" >Last</label>
                                    </div>

                                </FlexComponent>
                            </div>

                        </FlexComponent>

                        <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                            <div className='inputContainerDiv' style={{ margin: '0.5rem' }}>
                                <label style={{ fontWeight: 'bold' }} htmlFor="cb_DOB" >Co-Borrower Date of Birth</label>
                                <Field
                                    name={`cosigner_list[${cosingerIndex}].cb_DOB`}
                                >{({ field, form }) => (
                                    <Calendar
                                        id={field.name}
                                        value={field.value}
                                        style={{ width: '100%' }}
                                        onChange={(e) => form.setFieldValue(field.name, e.value)}
                                    />
                                )}</Field>
                            </div>

                            <div className='inputContainerDiv' style={{ margin: '0.5rem' }}>
                                <label htmlFor="cb_credit_score" style={{ fontWeight: 'bold' }}>CB Credit Score</label>
                                <Field type="number" id="cb_credit_score" name={`cosigner_list[${cosingerIndex}].cb_credit_score`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                            </div>

                            <div className='inputContainerDiv' style={{ margin: '0.5rem' }}>
                                <label htmlFor="cosigner_security_no" style={{ fontWeight: 'bold' }}>Co-Borrower ##</label>
                                <Field type="text" id="cosigner_security_no" name={`cosigner_list[${cosingerIndex}].cosigner_security_no`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} placeholder="000-00-0000" />
                            </div>

                        </FlexComponent>

                        <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                            <div className='inputContainerDiv' style={{ margin: '0.5rem', width: "45%" }}>
                                <label htmlFor="cb_title" style={{ fontWeight: 'bold' }}>CB Title</label>
                                <Field type="text" id="cb_title" name={`cosigner_list[${cosingerIndex}].cb_title`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur}
                                    placeholder="Registered Nurse, Queens General Hospital" />
                            </div>

                            <div className='inputContainerDiv' style={{ margin: '0.5rem', width: "30%" }}>
                                <label htmlFor="cb_yearly_income" style={{ fontWeight: 'bold' }}>CB Yearly Income</label>
                                <Field type="text" id="cb_yearly_income" name={`cosigner_list[${cosingerIndex}].cb_yearly_income`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                            </div>

                            <div className='inputContainerDiv' style={{ margin: '0.5rem', width: "25%" }}>
                                <label htmlFor="cb_time_at_position" style={{ fontWeight: 'bold' }}>Time at Position *</label> <br />
                                {['1 year or less', '2 years', 'Other'].map((option) => {
                                    return (
                                        <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                            <Field type="radio" id={option} name={`cosigner_list[${cosingerIndex}].cb_time_at_position`} value={option} as={RadioButton} />
                                            <label htmlFor={option} className="ml-2">{option}</label>

                                        </FlexComponent >
                                    );
                                })}
                                {values.cosigner_list.cb_time_at_position === 'Other' && (
                                    <div style={{ marginTop: '1rem' }}>
                                        <Field type="text" placeholder="Other" id="cb_other_time_at_position" name={`cosigner_list[${cosingerIndex}].cb_other_time_at_position`} as={InputText} style={{ width: '100%' }} />
                                    </div>
                                )}
                            </div>

                        </FlexComponent>

                        <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                            <div className='inputContainerDiv' style={{ width: '50%', margin: '0.5rem' }}>
                                <label htmlFor="cb_company_name" style={{ fontWeight: 'bold' }}>Company Name</label>
                                <Field type="text" id="cb_company_name" name={`cosigner_list[${cosingerIndex}].cb_company_name`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                            </div>
                            <div className='inputContainerDiv' style={{ width: '50%', margin: '0.5rem' }}>
                                <label htmlFor="cb_employment_type" style={{ fontWeight: 'bold' }}>Employment Type</label>
                                <Field
                                name={`cosigner_list[${cosingerIndex}].cb_employment_type`}
                                >{({ field }) => (
                                    <Dropdown
                                        id={field.name}
                                        value={values.cosigner_list[cosingerIndex]?.cb_employment_type}
                                        options={[
                                            { label: 'Salary - W2 Employee', value: 'Salary - W2 Employee' },
                                            { label: 'Hourly - W2 Employee', value: 'Hourly - W2 Employee' },
                                            { label: '1099 Independent Contractor', value: '1099 Independent Contractor' },
                                            { label: 'Self Employed/Business Owner', value: 'Self Employed/Business Owner' },
                                        ]}
                                        style={{ width: '100%' }}
                                        onChange={(event) => handleChange(`cosigner_list[${cosingerIndex}].cb_employment_type`)(event.value)}
                                        onBlur={handleBlur}
                                        placeholder="Select Type"
                                    />
                                )}</Field>
                            </div>

                        </FlexComponent>


                        <FlexComponent className='inputContainer' gap='1rem' alignItems='start' justifyContent="start">
                            <div className='inputContainerDiv' style={{ textAlign: 'start', width: '35%', margin: '0.5rem' }}>
                                <label style={{ fontWeight: 'bold' }} htmlFor="cb_status">Status</label> <br />
                                {["Currently working in this role", "I used to work in this role"].map((option) => {
                                    return (
                                        <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                            <Field type="radio" id={option} name={`cosigner_list[${cosingerIndex}].cb_status`} value={option} as={RadioButton} />
                                            <label htmlFor={option} className="ml-2">{option}</label>
                                        </FlexComponent >
                                    );
                                })}
                            </div>
                            <div className='inputContainerDiv' style={{ width: '28%', margin: '0.5rem' }}>
                                <label style={{ fontWeight: 'bold' }} htmlFor="cb_start_date" >Start Date</label>
                                <Field
                                    name={`cosigner_list[${cosingerIndex}].cb_start_date`}
                                >{({ field, form }) => (
                                    <Calendar
                                        id={field.name}
                                        value={field.value}
                                        style={{ width: '100%' }}
                                        onChange={(e) => form.setFieldValue(field.name, e.value)}
                                    />
                                )
                                    }</Field>
                            </div>
                            {values.cosigner_list.cb_status === "I used to work in this role" &&
                                <div className='inputContainerDiv' style={{ width: '28%', margin: '0.5rem' }}>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="cb_end_date" >End Date</label>
                                    <Field
                                        name={`cosigner_list[${cosingerIndex}].cb_end_date`}
                                    >{({ field, form }) => (
                                        <Calendar
                                            id={field.name}
                                            value={field.value}
                                            style={{ width: '100%' }}
                                            onChange={(e) => form.setFieldValue(field.name, e.value)}
                                        />
                                    )
                                        }</Field>
                                </div>
                            }
                        </FlexComponent>
                        <div style={{ textAlign: 'start', margin: '0.5rem' }}>
                            <label htmlFor="cb_work_history_description" >
                                <p style={{ fontWeight: 'bold' }}> Work History Description </p>
                            </label>
                            <Field rows={4} type="text" id="cb_work_history_description" name={`cosigner_list[${cosingerIndex}].cb_work_history_description`} as={InputTextarea} style={{ width: '100%' }} />
                            <p style={{ margin: "0.5rem 0" }}>Full 2 to 3 years of work history, # of years at this position, # of years in the profession/industry</p>
                        </div>

                        <FlexComponent className='inputContainer' flexDirection="column" gap='1rem' alignItems='start'>
                            <div className='inputContainerDiv' style={{ width: '100%', margin: '0 0.5rem' }}>
                                <label htmlFor="Home Address" style={{ fontWeight: 'bold' }}>Cosigner Home Address</label>
                                <Field type="text" id="cosigner_street_address" name={`cosigner_list[${cosingerIndex}].cosigner_street_address`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                                <label htmlFor="cosigner_street_address" >Street Address</label>
                                {/* <ErrorMessage name="title" component="div" className="error" style={{color:'red'}} /> */}
                            </div>
                            <FlexComponent className='inputContainer' gap="1rem">
                                <Field
                                name={`cosigner_list[${cosingerIndex}].cosigner_state`}
                                >{({ field }) => (
                                    <div className='inputContainerDiv' style={{ width: '50%', margin: '0 0.5rem' }}>
                                        <Dropdown
                                            id={field.name}
                                            value={values.cosigner_list[cosingerIndex]?.cosigner_state}
                                            options={State.getStatesOfCountry('US')?.map((state) => ({
                                                label: state.name,
                                                value: state.isoCode,
                                            })) || []}
                                            style={{ width: '100%' }}
                                            onChange={(event) => {
                                                handleChange(`cosigner_list[${cosingerIndex}].cosigner_state`)(event.value)
                                                handleChange(`cosigner_list[${cosingerIndex}].cosginer_city`)('')
                                            }}
                                            onBlur={handleBlur}
                                            placeholder="Select State"
                                        />
                                        <label htmlFor="state" >State</label>
                                    </div>
                                )}
                                </Field>
                                <div className='inputContainerDiv' style={{ width: '50%', margin: '0 0.5rem' }}>
                                    {/* <Field type="text" id="cosginer_city" name="cosginer_city" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />  */}
                                    {City.getCitiesOfState('US', values.cosigner_list[cosingerIndex]?.cosigner_state).length > 0 ? <Field
                                        name={`cosigner_list[${cosingerIndex}].cosginer_city`}
                                    >{({ field }) => (
                                        <Dropdown
                                            id={field.name}
                                            value={values[cosingerIndex]?.cosigner_list.cosginer_city}
                                            options={City.getCitiesOfState('US', values.cosigner_list[cosingerIndex]?.cosigner_state)?.map((state) => ({
                                                label: state.name,
                                                value: state.name,
                                            })) || []}
                                            style={{ width: '100%' }}
                                            onChange={(event) => handleChange(`cosigner_list[${cosingerIndex}].cosginer_city`)(event.value)}
                                            onBlur={handleBlur}
                                            placeholder="Select State"
                                        />
                                    )}</Field> : <Field type="text" id="cosginer_city" name={`cosigner_list[${cosingerIndex}].cosginer_city`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />}
                                    <label htmlFor="cosginer_city" >City</label>
                                </div>
                            </FlexComponent>
                            <div className='inputContainerDiv' style={{ width: '50%', margin: '0 0.5rem' }}>
                                <Field type="text" id="cosigner_zip_code" name={`cosigner_list[${cosingerIndex}].cosigner_zip_code`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                                <label htmlFor="cosigner_zip_code" >Zip Code</label>
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                            <div className='inputContainerDiv' style={{ width: '50%', margin: '0.5rem' }}>
                                <label htmlFor="cosigner_cell_phone_number" style={{ fontWeight: 'bold' }}>Cosigner Cell Phone Number</label>
                                <div className="p-inputgroup flex-1 login-input-div">
                                    <span className="p-inputgroup-addon" style={{ background: 'white' }}>
                                        +1
                                    </span>
                                    <Field mask="(999) 999-9999" type="text" id="cosigner_cell_phone_number" name={`cosigner_list[${cosingerIndex}].cosigner_cell_phone_number`} as={InputMask} style={{ width: '100%' }} onBlur={handleBlur} />
                                </div>
                            </div>
                            <div className='inputContainerDiv' style={{ width: '50%', margin: '0.5rem' }}>
                                <label htmlFor="cosigner_email" style={{ fontWeight: 'bold' }}>Cosigner Email</label>
                                <Field type="email" id="cosigner_email" name={`cosigner_list[${cosingerIndex}].cosigner_email`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                            </div>

                        </FlexComponent>
                        </div>
                        
                    </Dialog>
                )}
            </FieldArray>
        </div>

    )
}

export default CoSignerInfo