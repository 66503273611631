import { BubblyLink } from "../library/src";
import { Button } from 'primereact/button';
import './Nav.css'
import MobileNav from "./MobileNav";
import { useNavigate } from "react-router-dom";
import { TieredMenu } from "primereact/tieredmenu";
import FlexComponent from "../components/FlexComponent";
import logo from '../Images/Breadcrumb_logo.png'
import { useEffect, useRef, useState } from "react";
import { Avatar } from "primereact/avatar";
// import Notification from "./Notification";
import { getPipelines } from "../utils/urls";
import { useSelector } from "react-redux";
import axios from '../utils/axios';
import { NotificationComponent } from "./Notification";
import { InputText } from "primereact/inputtext";
import formLogo from '../Images/homeIcon.png'
import formIcon from '../Images/formIcon.png'


export const Nav = ({ handleLogout, setPipelineName, setBoardSections, isSuperuser, userLable, image, userName, is_leader }) => {
    const navigate = useNavigate()
    const menu = useRef(null);
    const formMenu = useRef(null)
    const superUserFormMenu = useRef(null)
    const userId = useSelector(state => state.user.userId)
    const appMenuu = useRef(null)
    const pipelineMenu = useRef(null)
    const availabilityMenu = useRef(null)
    const profileImgMenu = useRef(null)
    const userProfileImgMenu = useRef(null)
    const [pipelines, setPipelines] = useState([])

    const MyBubblyLink = ({ to = "", text = "", icon = '', style, className }) => (
        <BubblyLink to={to} colorStart="#da474e" colorEnd="#ffffff">
            <div className={className}
                // onClick={()=>{
                //     navigate(to)
                // }}
                style={style} icon={icon}>
                {text}
            </div>
        </BubblyLink>
    );

    const getAllPipelines = () => {
        if (userId) {

            const url = `${getPipelines}?userId=${userId}`
            axios.get(url, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        // console.log(response.data)
                        const options = response?.data?.map((pipeline) => ({
                            label: pipeline.name,
                            value: pipeline.id,
                        })) || [];
                        setPipelines(options)
                    }
                })
                .catch((error) => {
                    // setAppLeads(null)
                })
        }
    }
    useEffect(() => {
        getAllPipelines()
    }, [userId])

    // console.log("isSuperuser", isSuperuser)
    return (
        <FlexComponent flexDirection="column" alignItems="start" style={{ boxShadow: "-1px -2px 0px #ffffff40", borderRadius: "10px", top: 40, marginLeft: "4vw", marginTop: "20px", background: "rgba(255, 255, 255, 0.06)", width: "90vw", backdropFilter: "blur(2px)" }} >
            <FlexComponent style={{ width: "100%" }}>
                {isSuperuser ?
                    <FlexComponent className="navbarDiv" style={{ width: "100%" }} >
                        <nav className="navbar-item animate-in large-nav" style={{ animationDelay: "100ms", width: "100%" }}>

                            <FlexComponent style={{ width: "100%" }} >

                                <FlexComponent justifyContent="start" gap="2rem" style={{ width: "45%", paddingLeft: "4rem" }} >
                                    <img src={logo} style={{ width: '180px', height: '40px' }} alt="" />
                                    <span className="p-input-icon-right navbarSearchInput" style={{ width: "auto" }} >
                                        <InputText placeholder='Search...'
                                            // onChange={handleSearchChange} value={searchQuery}
                                            style={{ color: 'black', padding: "8px 15px", background: "black", borderRadius: "25px", width: "300px", border: "1px solid #575657" }} />
                                        <i className="pi pi-search" />
                                    </span>
                                </FlexComponent>

                                <FlexComponent style={{ width: "30%" }} justifyContent="center" gap="2rem" >
                                    <MyBubblyLink to="/" text="" className="pi pi-home" />

                                    <TieredMenu model={[
                                        { label: 'New Mortgage App', command: () => navigate('/new-app') },
                                        { label: 'New Buyer App', command: () => navigate('/new-buyer-app') },
                                        { label: 'New Loan SetUp Form', command: () => navigate('/new-loan-set-up-form') },
                                        { label: 'Short Form App', command: () => navigate('/new-app-short') },
                                    ]} popup ref={superUserFormMenu} breakpoint="767px" />

                                    {pipelines?.map(item => (
                                        <div style={{ cursor:"pointer"}} 
                                        // onClick={()=>{
                                        //      navigate(`/app-kanban/${item.value}`)
                                        // }} 
                                        >
                                            <MyBubblyLink style={{fontSize: "14px"}} to={`/app-kanban/${item.value}`} text={`${item.label.replace(/^[^\w]+/, "").trim()}`}  />
                                            {/* {item.label.replace(/^[^\w]+/, "").trim()} */}
                                        </div>
                                    ))}
                                    
                                    <Button style={{ padding: '0.5rem', background: "transparent", minWidth: "100px" }} label={<FlexComponent style={{ fontWeight: '400' }} ><img src={formIcon} style={{ width: "40px" }} alt="" /> </FlexComponent>} onClick={(e) => superUserFormMenu.current.toggle(e)} />

                                </FlexComponent>

                                <FlexComponent alignItems='center' justifyContent='end' gap='1rem' style={{ paddingRight: "4rem", textAlign: 'start', width: "25%" }}>
                                    <NotificationComponent setBoardSections={setBoardSections} />

                                    {image ?
                                        <Avatar style={{ cursor: "pointer", color: '#717B8C', height: "45px" }} onClick={(e) => profileImgMenu.current.toggle(e)} image={image} size="large" shape='circle' />
                                        :
                                        <Avatar style={{ cursor: "pointer", color: '#717B8C', height: "45px" }} onClick={(e) => profileImgMenu.current.toggle(e)} label={userLable} size="large" shape='circle' />
                                    }
                                    <div className="userText" >
                                        <p style={{ fontSize: '12px', color: '#717B8C', minWidth: "100px" }}>Welcome Back!</p>
                                        <p style={{ fontSize: '12px', color: '#717B8C' }}>{userName}</p>
                                    </div>

                                    {/* ///////// */}
                                    {/* ////////////////// */}

                                </FlexComponent>

                                <TieredMenu model={[
                                    {
                                        label: 'Leader Board',
                                        // icon: 'pi pi-search',
                                        items: [
                                            {
                                                label: 'Caller Leaderboard',
                                                icon: 'pi pi-bolt',
                                                command: () => navigate('/callers-leaderboard')
                                            },
                                            {
                                                label: 'Shopper Leaderboard',
                                                icon: 'pi pi-server',
                                                command: () => navigate('/shoppers-leaderboard')
                                            }
                                        ]
                                    },
                                    // {
                                    //     label: 'Pipelines',
                                    //     // icon: 'pi pi-search',
                                    //     items: pipelines?.map(item => ({
                                    //         label: item.label,
                                    //         command: () => navigate(`/app-kanban/${item.value}`)
                                    //     }))
                                    // },
                                    {
                                        label: 'Campaign Management',
                                        // icon: 'pi pi-search',
                                        items: [
                                            { label: 'Campaigns', command: () => navigate('/campaign') },
                                            { label: 'Teams', command: () => navigate('/team') },
                                            { label: 'Participants', command: () => navigate('/participant') },
                                            { label: 'Purchases', command: () => navigate('/purchase') },
                                        ]
                                    },
                                    {
                                        label: 'Settings',
                                        // icon: 'pi pi-search'
                                        command: () => navigate('/settings')
                                    },
                                    {
                                        label: 'Logout',
                                        // icon: 'pi pi-search'
                                        command: () => handleLogout()
                                    }
                                ]} popup ref={profileImgMenu} breakpoint="767px" />

                            </FlexComponent>

                        </nav>
                    </FlexComponent>
                    :
                    <FlexComponent className="navbarDiv" style={{ width: "100%" }} >
                        <nav className="navbar-item animate-in large-nav" style={{ animationDelay: "100ms", width: "100%" }}>

                            <FlexComponent style={{ width: "100%" }} >

                                <FlexComponent justifyContent="start" gap="2rem" style={{ width: "45%", paddingLeft: "4rem" }} >
                                    <img src={logo} style={{ width: '180px', height: '40px' }} alt="" />
                                    <span className="p-input-icon-right navbarSearchInput" style={{ width: "auto" }} >
                                        <InputText placeholder='Search...'
                                            // onChange={handleSearchChange} value={searchQuery}
                                            style={{ color: 'black', padding: "8px 15px", background: "black", borderRadius: "25px", width: "300px", border: "1px solid #575657" }} />
                                        <i className="pi pi-search" />
                                    </span>
                                </FlexComponent>

                                <FlexComponent style={{ width: "30%" }} justifyContent="center" gap="2rem" >
                                    <MyBubblyLink to="/" text="" className="pi pi-home" />

                                    <TieredMenu model={[
                                        { label: 'New Mortgage App', command: () => navigate('/new-app') },
                                        { label: 'New Buyer App', command: () => navigate('/new-buyer-app') },
                                        { label: 'New Loan SetUp Form', command: () => navigate('/new-loan-set-up-form') },
                                        { label: 'Short Form App', command: () => navigate('/new-app-short') },
                                    ]} popup ref={superUserFormMenu} breakpoint="767px" />

                                    {pipelines?.map(item => (
                                        <div style={{fontSize: "14px", cursor:"pointer"}} onClick={()=>{
                                             navigate(`/app-kanban/${item.value}`)
                                        }} >
                                            {item.label.replace(/^[^\w]+/, "").trim()}
                                        </div>
                                    ))}

                                    <Button style={{ padding: '0.5rem', background: "transparent", minWidth: "100px" }} label={<FlexComponent style={{ fontWeight: '400' }} ><img src={formIcon} style={{ width: "40px" }} alt="" /> </FlexComponent>} onClick={(e) => superUserFormMenu.current.toggle(e)} />
                                </FlexComponent>

                                <FlexComponent alignItems='center' justifyContent='end' gap='1rem' style={{ paddingRight: "4rem", textAlign: 'start', width: "25%" }}>
                                    <NotificationComponent setBoardSections={setBoardSections} />

                                    {image ?
                                        <Avatar style={{ cursor: "pointer", color: '#717B8C', height: "45px" }} onClick={(e) => userProfileImgMenu.current.toggle(e)} image={image} size="large" shape='circle' />
                                        :
                                        <Avatar style={{ cursor: "pointer", color: '#717B8C', height: "45px" }} onClick={(e) => userProfileImgMenu.current.toggle(e)} label={userLable} size="large" shape='circle' />
                                    }
                                    <div className="userText" >
                                        <p style={{ fontSize: '12px', color: '#717B8C', minWidth: "100px" }}>Welcome Back!</p>
                                        <p style={{ fontSize: '12px', color: '#717B8C' }}>{userName}</p>
                                    </div>

                                    {/* ///////// */}
                                    {/* ////////////////// */}

                                </FlexComponent>

                                <TieredMenu model={[
                                    {
                                        label: 'Leader Board',
                                        items: [
                                            {
                                                label: 'Caller Leaderboard',
                                                icon: 'pi pi-bolt',
                                                command: () => navigate('/callers-leaderboard')
                                            },
                                            {
                                                label: 'Shopper Leaderboard',
                                                icon: 'pi pi-server',
                                                command: () => navigate('/shoppers-leaderboard')
                                            }
                                        ]
                                    },
                                    // {
                                    //     label: 'Pipelines',
                                    //     items: pipelines?.map(item => ({
                                    //         label: item.label,
                                    //         command: () => navigate(`/app-kanban/${item.value}`)
                                    //     }))
                                    // },
                                    {
                                        label: 'Availability',
                                        items: [
                                            {
                                                label: 'Live Assignment',
                                                command: () => navigate(`/user-availability/${userId}`)
                                            },
                                            {
                                                label: 'Appointment',
                                                command: () => navigate(`/user-availability-appointment/${userId}`)
                                            },
                                        ]
                                    },
                                    ...(is_leader ? [
                                        {
                                            label: 'Participants',
                                            command: () => navigate('/participant')
                                        }
                                    ] : []),
                                    {
                                        label: 'Logout',
                                        command: () => handleLogout()
                                    }
                                ]} popup ref={userProfileImgMenu} breakpoint="767px" />


                            </FlexComponent>

                            {/* <MyBubblyLink to="/" text="Dashboard" />
                            {is_leader &&
                                <MyBubblyLink to="/participant" text="Participants" />
                            }

                            <TieredMenu model={[
                                { label: 'Caller Leaderboard', command: () => navigate('/callers-leaderboard') },
                                { label: 'Shopper Leaderboard', command: () => navigate('/shoppers-leaderboard') },
                            ]} popup ref={leaderBoardMenu} breakpoint="767px" />

                            <Button style={{ padding: '0.5rem' }} label={<FlexComponent gap="0.2rem" style={{ fontWeight: '400' }} >
                                Leaderboard <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>}
                                onClick={(e) => leaderBoardMenu.current.toggle(e)} />

                            <TieredMenu model={pipelines?.map(item => ({
                                label: item.label,
                                command: () => navigate(`/app-kanban/${item.value}`)
                            }))} popup ref={pipelineMenu} breakpoint="767px" />
                            <Button style={{ padding: '0.5rem' }} label={<FlexComponent gap="0.2rem" style={{ fontWeight: '400' }} >
                                Pipelines <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>}
                                onClick={(e) => pipelineMenu.current.toggle(e)} />

                            <TieredMenu model={[
                                { label: 'Live Assignment', command: () => navigate(`/user-availability/${userId}`) },
                                { label: 'Appointment', command: () => navigate(`/user-availability-appointment/${userId}`) },
                            ]} popup ref={availabilityMenu} breakpoint="767px" />
                            <Button style={{ padding: '0.5rem' }} label={<FlexComponent gap="0.2rem" style={{ fontWeight: '400' }} >
                                Availability <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>}
                                onClick={(e) => availabilityMenu.current.toggle(e)} /> */}


                            {/* <MyBubblyLink to={`/user-availability/${userId}`} text="User Availability" /> */}

                        </nav>
                        {/* <div className="logout-button-container" style={{ paddingLeft: "10px" }} >
                            <TieredMenu model={[
                                { label: 'New Mortgage App', command: () => navigate('/new-app') },
                                { label: 'New Buyer App', command: () => navigate('/new-buyer-app') },
                                { label: 'New Loan SetUp Form', command: () => navigate('/new-loan-set-up-form') },
                                { label: 'Short Form App', command: () => navigate('/new-app-short') }
                            ]} popup ref={formMenu} breakpoint="767px" />
                            <Button style={{ boxShadow: "none", padding: '0.5rem' }} label={<FlexComponent style={{ fontWeight: '400' }} >Forms <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>} onClick={(e) => formMenu.current.toggle(e)} />

                        </div> */}
                    </FlexComponent>
                }
                <img className="mobile-nav" src={logo} style={{ width: '180px', height: '40px' }} alt="" />

                <MobileNav isSuperuser={isSuperuser} is_leader={is_leader} />

            </FlexComponent>

        </FlexComponent>
    )
};
