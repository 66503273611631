import React, {useState} from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

export default function LeadsTable({leads, loading}) {

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        location_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        campaign_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        language_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        live_assignment: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        count: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
   
    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <div className='status'>{rowIndex}</div>;
      };

      function formatCreatedDate(isoDateString) {
        const dateObj = new Date(isoDateString);
    
        // Extract date components
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
    
        // Extract time components
        let hours = dateObj.getHours();
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const seconds = String(dateObj.getSeconds()).padStart(2, '0');
    
        // Determine AM/PM and adjust hours
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 24-hour to 12-hour format
    
        return `${year}-${month}-${day} ${String(hours).padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

      const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };
    
        const createdDateTemplate = (rowData) => {
            const formatedDate = formatCreatedDate(rowData.date_created)
            return <div>{formatedDate}</div>
        };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
                <div className='header-container-with-csv-export'>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
                </div>
                
        );
    };
    
    const header = renderHeader();

    return (
        <div className="card p-fluid">
            <DataTable value={leads} paginator rows={15} rowsPerPageOptions={[15, 30, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={header} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" className="padding-0" style={{ minWidth: '1rem'  }} body={srNoBodyTemplate} />
                <Column field="full_name" header="Name" style={{ minWidth: '13rem' }} sortable ></Column>
                <Column field="email" header="Email" style={{ minWidth: '2rem' }} sortable ></Column>
                <Column field="phone" header="Phone" style={{ minWidth: '2rem' }} sortable ></Column>
                <Column field="location_name" header="Location" style={{ minWidth: '15rem' }} sortable ></Column>
                <Column field="campaign_name" header="Campaign" style={{ minWidth: '15rem' }} sortable></Column>
                <Column field="language_name" header="Language" style={{ minWidth: '1rem'}} sortable></Column>
                <Column field="live_assignment" header="Live Assignment" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="count" header="Count" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="date_created" body={createdDateTemplate} header="Created Date" style={{ minWidth: '10rem' }} sortable></Column>
                {/* <Column field="reply_date" header="Replies" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="pipeline_last_updated_date" header="Oppertunities" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="pre_approval_date" header="PreApp" style={{ minWidth: '1rem' }} sortable></Column> */}
            </DataTable>
        </div>
    );
}